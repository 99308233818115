.editor-tooltip {
  position: fixed;
  // background: rgba(128, 128, 128, 0.6);
  background: rgba(172, 172, 172, 0.6);
  color: black;
  display: inline;
  padding: 10px;
  width: 270px;
  border-radius: 8px;
  z-index: 100;

  &.hide {
    display: none;
  }

  &::after {
    position: absolute;
    border: 10px solid rgba(172, 172, 172, 0.6);
    content: '';
    opacity: 0.6;
  }
  &-close {
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
    color: #008080,
  }

  &-title {
    font-weight: 500;
    margin-bottom: 5px;
  }
  &-content {
    font-size: 14px;
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  button {
    background: #008080;
    color: #eee;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #008080;
    cursor: pointer;
  
    &:hover {
      background: #03b7b6;
      border-color: #03b7b6;
    }
  }

  &.step-1 {
    top: 52%;
    right: 400px;

    &::after {
      right: -19px;
      top: calc(50% - 35px);
      border-color: transparent transparent transparent rgb(172, 172, 172);

    }
  }
  &.step-2 {
    top: 70.5%;
    right: calc(50% - 175px);

    @media (max-height: 906px) {
      top: 78%;
    }

    &::after {
      left: -19px;
      top: 15px;
      border-color: transparent rgb(172, 172, 172) transparent transparent;

    }
    &::before {
      position: absolute;
      border: 10px solid rgb(172, 172, 172);
      content: '';
      opacity: 0.6;
      left: -19px;
      bottom: 27px;
      border-color: transparent rgb(172, 172, 172) transparent transparent;
    }
  }

  &-last {
    position: absolute;
    border: 10px solid rgb(172, 172, 172);
    content: '';
    opacity: 0.6;
    left: -19px;
    top: 45px;
    border-color: transparent rgb(172, 172, 172) transparent transparent;
  }
}
