.render-wrap {
  background-color: rgba(255, 255, 255, 0.3);
  
  #drawCanvas {
    margin: 0;
    padding: 0;
    position: relative;
    background: transparent;
  }

  .video-text-canvas {
    background-color: rgba(255, 255, 255, 0.3);
  }

  #renderApp, #textApp, #textCanvas {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
  }

  .slides, .texts {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .element-animated, .element-animated * {
    position: absolute;
  }

  .text-item > div {
    margin: 0 auto;
  }
}