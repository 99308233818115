@mixin breakpoint($point) {
 
  @if $point==mobileL {
    @media (max-width: 690px) {
      @content;
    }
  }

  @if $point==mobileSm {
    @media (max-width: 520px) {
      @content;
    }
  }

  @if $point==mobileXs {
    @media (max-width: 420px) {
      @content;
    }
  }

}

@mixin transition-ease {
    transition: all 0.5s ease-in-out;
}