.desktop-editor {
  width: 100%;
  background-color: #f9fafb;
  .editor-header {
    width: 100%;
    height: 50px;
    box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.06), 0px 1px 3px 0px rgba(17, 24, 39, 0.10);

    &__content {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu-side {
        height: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        .hamburger {
          position: relative;
          height: 100%;
          width: 85px;
          display: flex;
          align-items: center;
          justify-content: center;

          &-icon {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          svg {
            cursor: pointer;
            font-size: 26px;
            path {
              stroke: #6B7280;
            }
          }
          
          &:hover {
            background-color: #008180;
            svg {
              cursor: pointer;
              path {
                stroke: white;
              }
            }
          }

          &.open {
            background-color: #008180;

            svg {
              path {
                stroke: white;
              }
            }
          }

          .content {
            position: absolute;
            display: none;
            top: 50px;
            left: 0px;
            height: 180px;
            width: 220px;
            padding: 28px 20px 38px 24px;
            background-color: white;
            box-shadow: 0px 4px 6px -2px rgba(17, 24, 39, 0.05), 0px 12px 16px -4px rgba(17, 24, 39, 0.10);
            z-index: 10;

            ul {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              li {
                cursor: pointer;
                color: #6B7280;
                font-weight: 500;
                font-size: 15px;
                @include transition-ease;

                &:hover {
                  opacity: 0.8;
                }
              }
            }

            &.open {
              display: block;
            }
          }
          .content-overlay {
            display: none;
            width: 100vw;
            height: 100vh;
            position: absolute;
            left: 0;
            top: 0;
            background-color: black;
            opacity: 0;
            z-index: 2;

            &.active {
              display: block;
            }
          }
        }
        .logo {
          margin-left: 15px;
          img {
            height: 40px;
          }
        }
      }

      .btns {
        margin-right: 15px;
        display: flex;
        align-items: center;

        .info-icon {
          margin-right: 20px;
          cursor: pointer;
        }
        button {
          width: 119px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          color: white;
          font-size: 13px;
          @include transition-ease;

          &.new-project {
            background-color: #4C1D95;
          }
          &.preview {
            background-color: #008180;
            margin-left: 12px;
          }

          &:hover {
            opacity: 0.8;
          }

          &.disabled {
            background-color: #e2e8f0;
            cursor: not-allowed;
            opacity: 1;
          }
        }
      }
    }
  }
  .editor-main {
    height: 51%;
    display: flex;
    border-bottom: 1px solid #D1D5DB;
    .editor-side-panel {
      height: 100%;
      width: 84px;
      background-color: white;
      z-index: 5;

      &.show-media {
        width: 364px;
      }
      &__content {
        height: 100%;
        height: 100%;
        display: flex;
        .side-navs {
          border: 1px solid #D1D5DB;
          width: 84px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            margin-top: 10px;
            padding: 0 10px;
            .icon {
              width: 30px;
              height: 30px;
              border-radius: 9px;
              background-color: #EFF1F4;
              display: flex;
              align-items: center;
              justify-content: center;
              @include transition-ease;
              svg {
                path {
                  @include transition-ease;
                }
              }
            }
            p {
              font-size: 10px;
              margin-top: 5px;
              text-align: center;
              line-height: 14px;
              @include transition-ease;
            }

            &:hover, &.active {
              .icon {
                background-color: #008180;
                svg {
                  path {
                    fill: white;
                  }
                }
              }
              p {
                color: #008180;
              }
            }
          }
        }
        .media-display {
          display: none;
          width: 280px;
          height: 100%;
          padding: 15px;
          text-align: center;

          &.show {
            display: block;
          }
          &__content {
            height: 100%;
            width: 100%;
            .title {
              display: flex;
              position: relative;
              font-size: 16px;
              color: #1F2937;
              font-weight: 600;
              svg {
                margin-left: 5px;
                cursor: pointer;
              }
              .close-icon {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
              }
            }
            .add-new-btns {
              margin-bottom: 12px;
              margin-top: 15px;
              button {
                width: 100%;
                border: 2px solid #008180;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 44px;
                margin-bottom: 5px;
                color: #008180;
                @include transition-ease;

                &:hover {
                  opacity: 0.8;
                }

                .icon {
                  margin-right: 7px;
                }

                &.uplaod {
                  .icon {
                    width: 26px;
                    height: 26px;
                    background-color: #6B7280;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }

            .media-items {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              height: 60%;
              overflow-y: scroll;

              &.voiceover {
                height: calc(60% - 49px);
              }
              .media-item {
                width: 48%;
                position: relative;
                margin-bottom: 4%;
                height: 70px;
                cursor: pointer;
                background-color: #F3F4F6;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 3px;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 7px;
                }
                &-duration {
                  position: absolute;
                  bottom: 6px;
                  left: 6px;
                  padding: 2px 6px;
                  background-color: #fff;
                  color: #2D2D2D;
                  border-radius: 4px;
                  font-size: 8px;
                  font-weight: 600;
                }
                &-select {
                  position: absolute;
                  border-radius: 50%;
                  border: 2px solid rgba(0, 0, 0, 0.30);
                  width: 18px;
                  height: 18px;
                  bottom: 6px;
                  right: 6px;

                  &.selected {
                    background-color: #008180;
                  }
                }
                &-type {
                  font-size: 9px;
                }
                &-name {
                  font-size: 10px;
                  word-break: break-all;
                }
              }
            }

            .add-media-btn {
              button {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                background-color: #008180;
                margin-top: 10px;
                color: white;
                @include transition-ease;

                &.disabled {
                  background-color: #e2e8f0;
                  cursor: not-allowed;
                  opacity: 1;
                }
                &:hover {
                  opacity: 0.8;
                }
              }
            }
            .editor-text-edit-panel {
              height: 95%;
              overflow-y: scroll;
            }
          }
        }
      }
    }
  }
}
.record-voiceover__btns {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  button {
    width: 113px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition-ease;
    &.undo-btn {
      margin-right: 12px;
      background: #0081801A;
      color: #008180;
    }
    &.keep-btn {
      background: #008180;
      color: #fff;
    }

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      background: rgb(199, 199, 199);
    }
  }
}
.editor-projects-modal {
  padding: 50px 20px 20px 20px;
  width: 90%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }

  .modal-tabs {
    display: flex;
    justify-content: space-between;
    position: relative;

    &::after {
      top: 33px;
      left: 0;
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: #E5E7EB;
    }
    .tabs {
      display: flex;
  
      .modal-tab {
        position: relative;
        font-size: 16px;
        color: #6B7280;
        text-align: center;
        width: 164px;
        font-weight: 500;
        @include transition-ease;
  
        &.completed {
          margin-left: 42px;
        }
        &.active {
          color: #4B5563;
          font-weight: 600;
          z-index: 2;
          &::after {
            top: 32px;
            position: absolute;
            content: '';
            height: 4px;
            background-color: #008180;
            width: 164px;
            left: 0;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      font-weight: 600;
      width: 149px;
      border-radius: 8px;
      height: 56px;
      font-size: 16px;
      outline: none;
      @include transition-ease;

      &:hover {
        opacity: 0.8;
      }
      &.next {
        background: #008180;
        color: #fff;
      }
      &.download {
        background: #0081801A;
        color: #008180;
        margin-right: 16px;
      }
    }
  }

  .projects {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0 20px 0;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    scrollbar-width: none;

    .project {
      width: 170px;
      height: 109px;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;
      cursor: pointer;
      @include transition-ease;
      &:hover {
        opacity: 0.8;
      }
      &-img {
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
          content: '\A';
          position: absolute;
          width: 100%; height:100%;
          top:0; left:0;
          background:rgba(0,0,0,0.3);
          border-radius: 5px;
          opacity: 1;
          transition: all 1s;
          -webkit-transition: all 1s;
        }
        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
          border-radius: 5px;
        }
      }

      &-duration {
        position: absolute;
        bottom: 4px;
        left: 4px;
        width: 41px;
        height: 17px;
        padding: 2px, 6px, 2px, 6px;
        background-color: #fff;
        color: #2D2D2D;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 600;
        text-align: center;
      }

      &-radio {
        position: absolute;
        bottom: 4px;
        right: 4px;
        margin-right: 0px;
        border: 2px solid white;

        &.active {
          border: none;
          border: 2px solid #008180;
        }
      }
    }
  }
}


.main-container {

  .spinner {
    width: calc(75% - 245px);
    height: 100px;
    position: absolute;
    z-index: 1000;
    text-align: center;
    display: inline-block;
    top: 25%;
  }
  
  .video-wrap {
    .text-content {
      word-wrap: normal;
      white-space: nowrap;
      > div {
        word-wrap: normal;
        white-space: nowrap;
      } 
    } 

    // background: rgb(30, 30, 30);
    .main-canvas-container {
      background: rgb(122, 122, 122);
      
      .slides {
        &:not(:first-child) {
          opacity: 0;
        }

        .slide-background-video {
          object-fit: contain;
          max-height: 100%;
          max-width: 100%;
        }

        img {
          position: absolute;
          max-height: 100%;
          max-width: 100%;
        }
      }

      .texts {
        opacity: 0;
      }

      .slides, .texts {
        position: absolute;
      }
    }

    .progress {
      height: 5px;
      display: block;
      width: 100%;
      background-color: #acece6;
      border-radius: 0px !important;
      margin: auto !important;
      overflow: hidden !important;

      .rangeslider-horizontal {
        height: 5px;
        margin: 0;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        background-color: #a7a8a830;

        .rangeslider__handle {
          height: 8px;
          width: 8px;
          border: 0;
          background-color: #982ddd;
          border-radius: 50%;
          box-shadow: none;

          &:after {
            display: none;
          }

          &:active, &:focus {
            outline: 0;
            box-shadow: none;
            border: 0;
          }
        }

        .rangeslider__fill {
          background-color: #982ddd;
        }
      }
      .range-slider__thumb {
        height: 8px;
        width: 8px;
        border: 0;
        background-color: #982ddd;
        box-shadow: none;
      }
      .range-slider__range {
        background-color: #982ddd;
      }
    }
  }

  .video-controller {
    margin-top: -8px;
    img {
      height: 24px;
      cursor: pointer;
      padding: 4px;
      width: auto;
      fill: #008180 !important;

      &.sel-clip-controller {
        width: 26px;
      }

      &.play-controller {
        width: 24px;
      }
    }

    .video-control {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% - 175px);

      img {
        margin-right: 3px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .video-time {
    padding: 5px 7px;
    background-color: #e0e5e8;
    border-radius: 2px;
    font-family: 'Open Sans', sans-serif;
    color: #4c6174;
    font-size: 13px;
  }
}

.main-container {
  &.updated .video-wrap {
    background: none;
  }

  &.updated {
    width: calc(100vw - 60px);

    &.expanded {
      width: calc(100vw - 320px);
    }
  }
}

.video-control {
  .editor-forward-icon, .editor-rewind-icon, .editor-play-icon, .editor-pause-icon {
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  .editor-pause-icon {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #eff1f3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
  }
}

.app-layout {
  display: block;
  margin: 0 auto;
    text-align: center;

  @media (max-width: 768px) {
    margin: 0;
  }

  img.block {
    margin-bottom: 10px;
  }

  div > .block:last-child {
    margin-bottom: 0;
  }

  .timeline {
    display: block;
    margin: 10px auto 37px;

    .timeline-buttons {
      margin-left: 97px;

      .btn-tl {
        width: 51px;
        height: 36px;
        padding: 0;
        text-align: center;
        line-height: 30px;
        border-width: 2px;
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 17px;
          margin-right: 0;
        }
      }
    }

    .temp-settings {
      width: 200px;
      height: 160px;
      background-color: #2c7a7b;
      color: #ffffff;
      right: 0;
      bottom: 30px;
      animation: show-modal-fadeIn ease-out 300ms;
    }

    .timeline-items-wrap {
      position: relative;
      display: flex;

      .justify-center {
        .timeline-item {
          &:nth-child(3) {
            .timeline-inner {
              background-color: #d6dfe4;
            }
          }
        }
      }

      .chakra-checkbox__control {
        &[data-checked] {
          > div {
            background-color: #008180;
          }
        }
        background-color: #ffffff;
      }

      .fade-buttons {
        .chakra-checkbox__control {
          margin: 5px;
        }
        [type="checkbox"] + span:not(.lever) {
          border-width: 1px;
        }
      }

      .timeline-items-flex {
        min-width: max-content;
        .timeline-sel-video {
          height: 66px;
          display: flex;
          align-items: center;
        }
        .timeline-item-video {
          height: 56px;
        }
        .timeline-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;
          -webkit-box-pack: justify;

          input {
            border-radius: 0;
            height: 16px;
            width: 16px;
            outline: 2px solid #a8bac4;
            outline-offset: -2px;
            margin: 0 9px 0 0;
          }

          .timeline-inner {
            width: 100%;
            position: relative;

            .item-icon {
              height: 27px;
              padding: 5px 5px 6px 6px;

              &.timeline-sel-video  {
                height: 66px;
              }
              img {
                height: 16px;
              }
            }
          }

          &:nth-child(4) {
            .timeline-inner {
              background-color: #d6dfe4;
            }
          }

          &:nth-child(5) {
            .timeline-inner {
              background-color: #c9d2d7;
            }
          }

          &:nth-child(6) {
            .timeline-inner {
              background-color: #bfc7ca;
            }
          }

          &:nth-child(7) {
            .timeline-inner {
              background-color: #bfc7ca;
            }
          }

          .checkboxes {
            .checkbox-wrap {
              margin-bottom: 16px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        &.timeline-sel-items {
          width: 75px;
          margin-top: 24px;

          .timeline-item {
            width: 75px;
            justify-content: flex-start;

            .timeline-inner {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              margin-right: 0;
            }

            &:first-child {
              .timeline-inner {
                .item-icon {
                  padding: 9px 5px 9px 2px;
                }
              }
            }

            &:nth-child(2) {
              .timeline-inner {
                // background-color: #d6dfe4;
              }
            }

            &:nth-child(3) {
              .timeline-inner {
                // background-color: #c9d2d7;
              }
            }

            &:nth-child(4) {
              .timeline-inner {
                // background-color: #bfc7ca;
              }
            }

            &:nth-child(5) {
              .timeline-inner {
                // background-color: #bfc7ca;
              }
            }
          }
        }

        &.timeline-main-preview {
          padding-bottom: 15px;
          width: 100%;
          position: relative;

          .timeline-item {
            .timeline-inner {
              min-height: 27px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;

              &.sound-list {
                display: flex;
                position: relative;
              }

              .sortable-list {
                display: flex;
                //opacity: 0.5;
                //z-index: 1;
                list-style: none;

                &.active {
                  opacity: 1;
                  z-index: 2;
                }
              }

              .scene-item {
                position: relative;
                flex-grow: 0;
                min-width: 0;
                flex-shrink: 0;

                &.bg-item {
                  background-size: contain !important;
                }

                .before, .after {
                  width: 0;
                  opacity: 0;
                }

                &.active {
                  .before {
                    background: url('../images/editor/pointer.png');
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 66px;
                    width: 9px;
                    cursor: pointer;
                    opacity: 1;
                  }

                  .after {
                    content: ' ';
                    background: url('../images/editor/pointer.png');
                    transform: rotate(180deg);
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 66px;
                    width: 9px;
                    cursor: pointer;
                    opacity: 1;
                  }
                }

                &.sound-item {
                  position: absolute;

                  &.active {
                    border-right: 2px solid #3544a8;
                    border-left: 2px solid #3544a8;
                    font-weight: bold;
                    color: #3544a8;
                  }
                }
              }

              .sortable-list {
                .text-slide-item {
                  text-overflow: ellipsis;
                  flex-grow: 0;
                  min-width: 0;
                  flex-shrink: 0;
                  list-style: none;

                  .moveable-control-box {
                    display: none !important;
                    border: 0 !important;

                    .moveable-line {
                      border: 0 !important;
                      height: 0 !important;
                    }
                  }

                  &.active-text {
                    border-color: #3544a8;
                    font-size: 80%;
                    padding: 0 15px;

                    .moveable-control-box {
                      display: block !important;
                      z-index: 5 !important;

                      .moveable-control {
                        background: #3544a8 !important;
                        border: 0 !important;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(2) {
              .timeline-inner {
                min-height: 66px;
              }
            }
          }

          .timeline-item-texts {
            position: absolute;
            top: 31px;
            right: 0;

            .timeline-item {
              .timeline-inner {
                background-color: transparent;
              }

              &:nth-child(2) {
                .timeline-inner {
                  min-height: 27px;
                }
              }
            }
          }
        }

        .timeline-slider {
          top: 31px;

          .rangeslider-horizontal {
            height: 31px;
            margin: -31px 0 0 -2.5px;
            background: transparent;
            box-shadow: none;

            .rangeslider__fill {
              box-shadow: none;
              border-radius: 0px;
            }

            .rangeslider__handle {
              width: 5px;
              height: calc(35px + (33px * 6));
              border: 0;
              background-color: #3544a8;
              border-radius: 0;
              box-shadow: none;
              top: 0;
              transform: none;
              z-index: 1;

              &:after {
                display: none;
              }

              &:active, &:focus {
                outline: 0;
                box-shadow: none;
                border: 0;
              }
            }

            .rangeslider__fill {
              background-color: transparent;
            }
          }

          .maintimeline-slider {
            .range-slider__thumb {
              background-color: #3544a8;
              width: 5px;
              height: 233px;
              top: 100px;
              border-radius: 0%;
            }
            .range-slider__thumb[data-lower] {
              width: 0;
            }
            .range-slider__range {
              background: transparent;
            }
          }
        }
      }

      .scrollbar-container {
        min-width: calc(100% - 75px);
      }
    }

    &.updated {
      .timeline-buttons {
        display: flex;
        margin-left: 85px;
        margin-right: 5px;
        justify-content: space-between;
        &__medias {
          display: flex;
          .media-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 32px;
            border-radius: 5px;
            background-color: white;
            margin-right: 6px;
            cursor: pointer;
            @include transition-ease;

            &:hover {
              opacity: 0.8;
            }
            &.disabled {
              cursor: not-allowed;
              opacity: 0.5;
              &:hover {
                opacity: 0.5;
              }
            }
          }
        }

        &__editor {
          display: flex;
          align-items: center;

          button {
            background-color: #008180 !important;
            border-radius: 8px;
          }
          .zoom-input-container {
            img, svg {
              cursor: pointer;
              @include transition-ease;
              &:hover {
                opacity: 0.8;
              }

              &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
                &:hover {
                  opacity: 0.5;
                }
              }
            }
            .zoom-control {
              margin-left: 8px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .mobile-footer {
    &.ap-1 {
      display: none;
    }
  }

  width: 100vw !important;

  img {
    cursor: pointer;
  }
}

.checkbox-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      right: 11px;
      bottom: 1px;
      content: ' ';
      display: none;
      background-color: #c621d5;
    }

    &:before {
      position: absolute;
      left: 32%;
      top: 50%;
      width: 10px;
      height: 8px;
      transform: translate(-50%, -50%);
      content: ' ';
      display: none;
      background-image: url('../images/editor/icons/correct.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 2;
    }

    &:checked {
      &:before, &:after {
        display: block;
      }
    }
  }
}

input[type=checkbox] {
  // -moz-appearance: initial;
}

.panel {
  padding: 20px 25px 60px;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .btn-publish {
    height: 50px;
    width: 200px;
    background-color: #008180;
    border-radius: 10px;
    color: #fff;
    border: 0;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif;
    outline: 0;

    @media (max-width: 1195px) {
      width: 152px !important;
    }

    @media (max-width: 1024px) {
      width: 70px !important;
      font-size: 10px !important;
    }
  }

  .dots {
    background-color: #008180;
    height: 50px;
    width: 35px;
    border-radius: 10px;

    .dot {
      background-color: #fff;
      height: 7px;
      width: 7px;
      border-radius: 100%;
      margin-bottom: 1px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .edit-buttons {
    display: block;
    width: 100%;
    margin-top: auto;
  }

  @media (max-width: 1024px) {
    .dots {
      margin-top: 38px;
    }
  }

  @media (max-width: 768px) {
    .dots {
      margin-top: 18px;
    }
  }

  @media (max-width: 960px) {
    padding: 10px 15px 55px;
  }

  .ratio-action-dropdown {
    top: 180px;
    background-color: rgba(14, 208, 210, 0.8);
  }

  .btn-edit {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #4c6174;
    padding: 6px 12px;
    width: 152px;
    border: 2px solid #b9c4e5;
    border-radius: 10px;
    margin-bottom: 6px;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    outline: 0;

    img {
      margin-right: 13px;
    }
  }

  .btn-publish,
  .btn-edit,
  .dots {
    transition: all 100ms ease-in-out;
  }

  .btn-publish:hover, .dots:hover {
    background-color: #03b7b6;
  }

  .btn-edit:hover, .btn-tl:hover {
    border-color: #909598;
  }

  .btn-edit.clicked,
  .btn-edit:active, .btn-tl:active {
    border-color: #c520d6;
  }
}

.import-files-modal {
    top: 35px;
    right: 35px;
    bottom: 35px;
    min-height: calc(80vh - 70px);
    left: 35px;
    background-color: #f2fbff;
    animation: show-modal-fadeIn ease-out 500ms;

    @media (max-width: 1024px) {
      top: 20px;
      right: 20px;
      bottom: 20px;
      left: 20px;
    }

    @media (max-width: 768px) {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 0;
      overflow-x: hidden;
    }

    .import-type {
      width: 100%;

      .upload-file {
        cursor: pointer;
        display: inline-block;
      }

      .right .fa-trash-alt {
        margin-bottom: -2px;
        cursor: pointer;
      }

      .type-header {
        padding: 8px 40px 8px 40px;
        border-bottom: 1px solid #ddd;
      }

      &:first-child {
        .type-header {
          padding-top: 8px;
        }
      }

      .left {
        display: flex;
        align-items: center;

        .type-title {
          margin-left: 8px;
          color: #4c6174;
          font-size: 18px;
        }
      }

      .right {
        .btn-publish {
          width: 170px;
          height: 50px;

          @media (max-width: 768px) {
            margin-top: 20px;
          }
        }
      }

      .type-content {
        padding: 15px 40px;

        @media (max-width: 768px) {
          justify-content: flex-start;
          flex-wrap: nowrap;
          overflow-x: scroll;
        }

        .media {
          margin-right: 15px;

          @media (max-width: 768px) {
            margin: 5px 15px;
          }
        }

        &.img22 {

          overflow: auto;

          .media {
            width: 160px;
            height: 90px;
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            background-color: rgba(0, 0, 0, 0.55);

            &:after {
              content: ' ';
              position: absolute;
              width: 32px;
              height: 32px;
              bottom: 5px;
              right: 5px;
              background-repeat: no-repeat;
              background-size: cover;
              background-image: url('../images/editor/media.png');
            }

            &.active {
              &:after {
                background-image: url('../images/editor/media-active.png');
              }
            }

            img {
              height: 90px;
            }
          }
        }

        &.img:not(.img22) {
          max-height: 206px;
          overflow: auto;

          .media {
            width: 160px;
            height: 90px;
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            background-color: rgba(0, 0, 0, 0.55);

            &:after {
              content: ' ';
              position: absolute;
              width: 32px;
              height: 32px;
              bottom: 5px;
              right: 5px;
              background-repeat: no-repeat;
              background-size: cover;
              background-image: url('../images/editor/media.png');
            }

            &.active {
              &:after {
                background-image: url('../images/editor/media-active.png');
              }
            }

            img {
              height: 90px;
            }
          }

          @media (max-width: 768px) {
            img {
              min-width: 160px;
            }
          }
        }

        &.audio {
          justify-content: flex-start;
          max-height: 150px;
          overflow-y: scroll;
          .media {
            padding: 10px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              text-align: center;
              word-break: break-all;
              max-width: 122px;
            }

            img {
              height: 40px;
              width: 40px;
            }
          }

          .active {
            background-color: #f4ebf5;
          }
        }

        &.brand {
          min-height: 75px;

          .media {
            img {
              height: 50px;
            }
          }
        }

        p {
          margin-top: 5px;
          font-weight: 600;
          color: #4c6174;
        }
      }
    }
}

.import-footer {
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  
  .type-content {
    background-color: #ebebeb;

    @media (max-width: 768px) {
      justify-content: center;
    }

    .left {
      overflow-x: scroll;
      max-width: 100%;
      .left-item {
        height: 62px;
        margin-right: 10px;

        img {
          height: 62px;
        }

        &.audio {
          img {
            height: 32px;
          }

          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            text-align: center;
            word-break: break-all;
            max-width: 100px;
          }
        }
      }
    }
  }
}

.btn-add {
  height: 44px;
  width: 260px;
  background-color: #008180;
  border-radius: 8px;
  color: #fff;
  letter-spacing: 1px;
  font-family: 'Open Sans', sans-serif;
  transition: all 100ms ease-in-out;
}

.editor-text-panel {
  width: 280px;
  height: 100vh;
  min-width: 280px;

  & + .app-layout {
    @media (max-width: 1195px) {
      width: calc(100% - 300px) !important;
    }

    @media (max-width: 1024px) {
      width: calc(100% - 400px) !important;
    }

    @media (max-width: 768px) {
      width: calc(100% - 480px) !important;
    }
  }

  h6 {
    color: #4c6174;
    font-size: 14px;
  }

  .panel-select {
    &:after {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #333;
      position: absolute;
      top: 40%;
      right: 20px;
      content: "";
      z-index: 98;
    }
  }
}

.publish-screen {
  &.review-publish-modal {
    max-width: 1200px;
    margin: 0 auto;

    .screen-title {
      text-align: center;
      font-weight: 600;
      color: #4B5563;
      font-size: 30px;
      margin-bottom: 50px;
    }

    .loaders {
      display: flex;
      justify-content: space-between;

      .loader {
        width: 49%;

        .title {
          color: #4B5563;
          font-size: 16px;
          margin-left: 5px;
          margin-bottom: 5px;
          font-weight: 600;
        }

        .progress-bar {
          background-color: #E5E7EB;
          border-radius: 30px;
          height: 5px;
          width: 100%;
          @include transition-ease;

          &.active {
            background-color: #008180;
          }
        }
      }
    }

    .preview, .review-publish {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      flex-wrap: wrap;

      .project-review {
        width: 49%;

        @media (max-width: 768px) {
          width: 98%;
        }
      }
      .aspect-selections{
        width: 49%;

        @media (max-width: 768px) {
          width: 98%;
        }

        .select-box {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid #d9d9d9;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          background-color: white;
          cursor: pointer;
          @include transition-ease;

          span {
            display: none;
            font-size: 12px;
          }

          &.selected {
            background-color: #008180;
            span {
              display: inline-block;
            }
          }
        }
        .select-all {
          display: flex;
          padding: 10px;
          cursor: pointer;
          .title {
            margin-left: 10px;
            color: #1F2937;
            font-weight: 500;
            font-size: 17px;
          }
        }
        .select-aspect-ratios {
          margin-top: 5px;
          margin-bottom: 15px;
          .select-aspect-ratio {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            border: 2px solid #D1D5DB;
            border-radius: 7px;
            margin-bottom: 10px;
            cursor: pointer;
            @include transition-ease;
            @media (max-width: 768px) {
              padding: 5px;
            }

            &.selected {
              border: 2px solid #008180;
            }

            &:hover {
              opacity: 0.8;
              border: 2px solid #008180;
            }
            .details {
              display: flex;
              align-items: center;
              .ratio {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #E5E7EB;
                margin-left: 14px;
                margin-right: 18px;
                
                @media (max-width: 768px) {
                  margin-left: 8px;
                  margin-right: 10px;
                }

                span {
                  font-size: 9px;
                  color: #6B7280;
                }

                &.full-vertical {
                  width: 28px;
                  height: 38px;
                  @media (max-width: 768px) {
                    width: 22px;
                    height: 30px;
                  }
                }
            
                &.semi-vertical {
                  width: 35px;
                  height: 38px;
                  @media (max-width: 768px) {
                    width: 30px;
                    height: 33px;
                  }
                }
            
                &.square {
                  width: 38px;
                  height: 38px;
                  @media (max-width: 768px) {
                    width: 30px;
                    height: 30px;
                  }
                }
            
                &.landscape {
                  width: 60px;
                  height: 38px;
                  @media (max-width: 768px) {
                    width: 45px;
                    height: 30px;
                  }
                }
              }
              p {
                color: #4B5563;
                font-size: 13px;
              }
            }
            .supported-socials {
              p {
                color: #4B5563;
                font-size: 13px;
              }
              .icons {
                display: flex;
                justify-content: flex-end;
                margin-top: 6px;

                svg {
                  margin-right: 7px;
                  @media (max-width: 768px) {
                    margin-right: 3px;
                  }
                }
              }
            }
          }
        }
        .btns {
          margin-top: 25px;
          button {
            width: 100%;
            border-radius: 7px;
            border: 1px solid #008180;
            font-weight: 600;
            height: 40px;
            font-size: 15px;
            margin-top: 12px;
            @include transition-ease;

            &.generate, &.publish-all {
              background-color: #008180;
              color: white;
            }
            &.back {
              color: #008180;
              background-color: white;
            }

            &:hover {
              opacity: 0.8;
            }

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }

      &__content {
        margin: 0 auto;
        width: 50%;
        h4 {
          font-size: 18px;
          font-weight: 500;
          text-align: center;
        }
        .aspect-selections{
          width: 100%;

          .select-aspect-ratios {
            .select-aspect-ratio {
              border-color: #D1D5DB;
              cursor: default;

              &.hide {
                display: none;
              }

              .exports {
                display: flex;
                align-items: center;
                .export {
                  margin-right: 10px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  background-color: #F3F4F6;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @include transition-ease;

                  &:hover {
                    opacity: 0.8;
                  }

                  &.disabled {
                    opacity: 0.5;

                    &:hover {
                      opacity: 0.5;
                    }
                  }

                  svg {
                    color: red;
                    g {
                      color: red;
                    }
                    rect {
                      color: red;
                    }
                    path {
                      color: red;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .screen-title {
    font-size: 26px;
    color: #545c68;
    font-weight: 700;
    text-align: center;
    display: block;
    margin: 10px auto 15px;
    line-height: 40px;
    opacity: .8;
  }

  .subheading {
    font-weight: bold;
    display: block;
    text-align: center;
    color: #909598;
  }

  .social-shares, .social-shares .social {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .social {
    img {
      width: 24px;
      height: 24px;
      margin-left: 8px;
    }

    &:first-child {
      .checkbox-wrap {
        margin-left: 0;
      }

      img {
        margin-left: 0;
      }
    }

    input {
      border-radius: 0;
      height: 16px;
      width: 16px;
      outline: 2px solid #a8bac4;
      outline-offset: -2px;
      margin: 0 9px 0 0;
    }
  }

  .panel {
    float: right;
    padding-bottom: 125px;
    padding-top: 5px;

    @media only screen and (max-width: 1195px) {
      width: 20%;
      padding-bottom: 0;

      .btn-publish {
        width: 90%;
      }

      .btn-edit {
        width: 80%;
      }
    }

    @media only screen and (max-width: 992px) {
      .btn-publish {
        width: 120px;
        right: 30px;
        top: 10px;
      }
    }

    .btn-edit {
      justify-content: center;
    }
  }
}

.review-publish-page, .publish-page {
  padding: 5px 0 30px;
}

.review-publish-page {
  .main-container {
    margin: 0 auto;
    display: block;

    @media only screen and (max-width: 700px) {
      .video-wrap {
        width: 100%;
      }

      .video {
        height: 100vh;
        object-fit: cover;
        object-position: right;
      }

      .video-controller {
        position: absolute;
      }
    }

    .video-controller {
      justify-content: start;

      .video-control {
        margin-left: 0;
        width: calc(100% - 183px);
      }
    }
  }

  .preview-ratios-wrap {
    display: flex;
    justify-content: space-between;
    max-width: 75%;
    margin: 0 auto;
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    padding: 5px 30px;
    border: 1px solid grey;

    .preview-ratio {
      img {
        max-width: 50px;
        margin: 0;
      }

      &:first-child {
        img {
          max-width: 100px;
        }
      }
    }

    @media only screen and (max-width: 992px) {
      width: 100%;
      max-width: none;
    }
  }
}

.preview-ratio {
  position: relative;
  cursor: pointer;

  &.selected-ap:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #c621d5;
    opacity: .3;
  }
}

.social-tabs {
  border: 0 !important;
  background-color: transparent;
  text-align: left;

  .ui-tabs-nav {
    display: inline-block;
    border-bottom: 2px solid #008180;
    margin-bottom: -3px;
  }

  .tab-item {
    background-color: #fff !important;
    border-radius: 10px;
    padding: 8px 8px;
    border: 1px solid grey !important;

    .social {
      img {
        height: 30px;
        width: auto;
      }
    }
  }
}

.tabin-flex-start {
  .social-shares {
    justify-content: flex-start;
  }
}

.ui-tabs-nav li:last-child {
  margin-right: 0 !important;
}

@media only screen and (max-width: 700px) {
  .review-publish-page.publish-screen .btn-publish.absolute-btn {
    display: none;
  }

  .save-template, .template-content h3 {
    text-align: center;
  }

  .screen-title {
    max-width: 100%;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .social {
    .checkbox-wrap {
      margin-left: 8px;
    }
  }

  .social img {
    margin-left: 0;
  }

  .publish-screen .social-shares,
  .social-shares .social {
    flex-wrap: wrap;
    margin: 5px;
  }

  .review-publish-page .preview-ratios-wrap {
    flex-wrap: wrap;
    width: 70%;
  }

  .review-publish-page .preview-ratios-wrap > div {
    flex: 1;
    flex-basis: 38%;
    margin: 0 0 15px 0;
  }

  .review-publish-page .preview-ratios-wrap > div:last-child {
    margin-bottom: 0;
  }

  .preview-ratio.selected-ap:before {
    width: 50px;
    left: 50%;
    transform: translateX(-50%);
  }

  .preview-ratio.selected-ap:first-child:before {
    width: 100px;
  }

  .preview-ratio:nth-child(3) {
    margin-bottom: 0 !important;
  }

  .preview-ratio {
    text-align: center;
  }
}

@media only screen and (max-width: 700px) and (min-aspect-ratio: 1/1) {
  .video {
    height: 70vh;
    width: 100%;
    position: relative;
  }

  .video-overlay {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .dots {
    float: none;
    margin-left: 0;
    width: 23px;
    height: 64px;
  }

  .video-wrap {
    .progress:after {
      position: absolute;
    }
  }
}

@media only screen and (max-width: 500px) {
  .publish-item {
    flex-wrap: wrap;
    margin-bottom: 30px;

    .prev-ratio {
      margin: 5px auto 15px;
      text-align: center;

      img {
        margin: 0 20px
      }
    }
  }

  .publish-notify {
    flex-wrap: wrap;

    input {
      margin-top: 10px;
      width: 100%;
    }
  }

  .review-publish-page {
    .preview-ratios-wrap {
      width: 90%;
    }
  }
}

@media only screen and (max-height: 300px) {
  .review-publish-page {
    .preview-ratios-wrap {
      width: 90%;
    }
  }
}

.disabled {
  opacity: 0.5;
  filter: grayscale(1);
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes shake-error {
  0% {
    margin-left: -5px;
  }
  25% {
    margin-left: 5px;
  }
  50% {
    margin-left: -5px;
  }
  75% {
    margin-left: 5px;
  }
  100% {
    margin-left: 0;
  }

}

.video-gradient {
  background: linear-gradient(180deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5), transparent)
}

.disabled-ratio-box {
  background: linear-gradient(180deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5), transparent);
  border-radius: 20px;
}

.fixed-height-aspect-ratio {
  width: calc(var(--aspect-ratio) * var(--height));
  height: calc(var(--height));
}

.preview-ratio-button.fixed-height-aspect-ratio {
  transition: filter 0.2s;
}

.preview-ratio-button.fixed-height-aspect-ratio:hover {
  filter: hue-rotate(0) drop-shadow(2px 4px 6px black);
  transition: filter 0.2s;
}

.preview-ratio-button.fixed-height-aspect-ratio.active {
  filter: sepia(100%) hue-rotate(140deg) brightness(100%) saturate(500%) drop-shadow(2px 4px 6px black);
  transition: filter 0.2s;
}

.percent-height-aspect-ratio {
  width: calc(var(--aspect-ratio) * var(--width));
  height: calc(((1 - var(--is-too-wide)) * 100%) + (var(--is-too-wide) * var(--height-value-when-too-wide)));
  --stage-ratio: calc(16/9);
  --square-ratio: calc(1/1);
  --inverted-aspect-ratio: calc(1 / var(--aspect-ratio));
  --inverted-stage-ratio: calc(1 / var(--stage-ratio));
  --width: calc(var(--height-ratio) * var(--height));
  --height-ratio: calc((var(--is-too-wide) * 1) + ((1 - var(--is-too-wide)) * var(--inverted-stage-ratio)));
  --is-too-square: clamp(0, var(--aspect-ratio) - var(--square-ratio), 1);
  --is-too-wide: clamp(0, var(--aspect-ratio) - var(--stage-ratio), 1);
  --height-value-when-too-wide: calc((var(--stage-ratio) * var(--inverted-aspect-ratio)) * 100%);
  --is-too-near: clamp(1, var(--aspect-ratio) - var(--stage-ratio), 0);
}

.audio-without-margins {
  padding: 0px !important;
  margin: 0px !important;
}

.video-time {
  padding: 5px 7px;
  background-color: #e0e5e8;
  border-radius: 2px;
  font-family: 'Open Sans', sans-serif;
  color: #4c6174;
  font-size: 13px;
  flex-wrap: nowrap;
}

.add-btn {
  margin-top: 31px;
  cursor: pointer;
  z-index: 2;
  min-height: calc(100% - 46px);
  min-width: 270px;
  width: 270px;
  img:not(.img22) {
    height: 168px;
    transition: all 100ms ease-in-out;
  }

  &:hover {
    img {
      opacity: .65;
    }
  }
}

.item-text {
  text-align: right;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: #3544a8;
  border-radius: 0px 10px 10px 0px;
}

.timeline-box {
  // width:  calc(100vw - 77px - 280px);
  width:  calc(100vw - 77px);
  
  @media (max-width: 1024px) {
    // width:  calc(100vw - 77px - 290px);
    width:  calc(100vw - 77px);
  }
}

.timeline-box.shrinked {
  width:  calc(100vw - 77px - 750px);
  
  @media (max-width: 1024px) {
    width:  calc(100vw - 77px - 400px);
  }
}

.timeline-header {
  display: flex;
  align-items: center;

  .time-steps {
    height: 24px;
    padding: 0;
    color: #4c6174;
    opacity: .4;
    font-weight: bold;

    .ruler-small,
    .ruler-main {
      background-color: #4c6174;
      height: 3px;
      width: 1px;
      min-width: 1px;
      margin-right: 19px;
      position: relative;
      display: inline-block;
      vertical-align: bottom;
      cursor: default;
      pointer-events: none;
    }

    .ruler-main {
      height: 6px;
      width: 2px;
      margin-right: 18px;

      &:after {
        content: attr(id);
        font-size: .75em;
        color: #4c6174;
        transform: translateX(-52%);
        bottom: 4px;
        position: absolute;
        cursor: default;
        pointer-events: none;
        width: 35px;
        text-align: right;
      }

      &:first-child:after {
        transform: translateX(0);
        text-align: left;
      }
    }
  }
}

.dropdown-content li {
  min-height: auto;
}

[type="checkbox"] + span:not(.lever) {
  margin: auto 10px;
  border: 2px solid #b9c4e5;
}

[type="checkbox"][disabled] + span:not(.lever) {
  background-color: #b9c4e5;
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  border: 2px solid #3544a8;
  background-color: #3544a8;
}


.scene-preview {
  max-width: 263px;
  height: 100px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #D1D5DB;

  ul {
    padding-left: 10px;

    li {
      list-style: disc;
      white-space: nowrap;
    }
  }
}

.tl-buttons {
  .btn-tl {
    height: 42px;
    width: 42px;
    line-height: 42px;
    font-size: 16px;
    background-color: #F3F4F6;
    color: #374151;
    margin-right: 5px;
    border-radius: 5px;
    transition: all 100ms ease-in-out;
  }
}

.animation-item {
  margin-bottom: 13px;

  .btn-edit {
    font-family: 'Open Sans', sans-serif;
    color: #4B5563;
    padding: 9px 12px;
    width: 152px;
    border: 2px solid #b7c7d0;
    border-radius: 10px;
    transition: all 100ms ease-in-out;
    margin-right: 10px;
    font-size: 12px;
    background-color: #F3F4F6;

    &:hover {
      border-color: #008180;
    }

    &.active {
      border-color: #008180;
    }
  }
}

.ani-slider {
  height: 5px;

  .rangeslider-horizontal {
    height: 5px;
    margin: 0;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background-color: #a7a8a830;

    .rangeslider__handle {
      height: 8px;
      width: 8px;
      border: 0;
      background-color: #982ddd;
      border-radius: 50%;
      box-shadow: none;

      &:after {
        display: none;
      }

      &:active, &:focus {
        outline: 0;
        box-shadow: none;
        border: 0;
      }
    }

    .rangeslider__fill {
      background-color: #982ddd;
    }
  }

  .range-slider {
    height: 4px;
    .range-slider__thumb {
      background-color: #008180;
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }
    .range-slider__thumb[data-lower] {
      width: 0;
    }
    .range-slider__range {
      background-color: #008180;
    }
  }
}

.menu-list {
  --tw-bg-opacity: 1;
  background-color: rgba(44, 122, 123, var(--tw-bg-opacity)) !important;
  color: white !important;
}

.moveable-control-box {
  z-index: initial !important;
}

.tab-list {
  border-color: gray !important;
  flex-flow: wrap;
}

.tabin-flex-just {
  max-width: 100%;
}

.review-publish-stepper {
  width: 100%;
}

@media (max-width: 960px) {
  .side-panel {
    position: absolute;
    right: 0px;
    z-index: 3;
  }
}

@media (max-height: 648px) {
  .editor-top {
    justify-content: left;
    margin-left: 15px;
  }
  .side-panel {
    position: absolute;
    right: 0px;
    z-index: 3;
  }
}

.no-transform {
  transform: none !important;
}


.zoom-control[type='range'] {
  accent-color: #008180;
  height: 3px;
}

// CropperPlaybackControl
.cropper-playback-control {
  width: 80%;

  &__container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    width: 80%;

    &-slider {
      width: calc(100% - 100px);
    }

    &-play {
      width: 20px;
      margin-right: 30px;
      cursor: pointer;
    }

    &-timer {
      width: 80px;
      margin-left: 30px;
      font-size: 12px;
      opacity: 0.7;
    }
  }

  &.editor-review {
    width: 97%;
    margin: 0 auto;

    .cropper-playback-control__container {
      width: 100%;
      margin-top: 10px;

      &-play {
        margin-right: 15px;
        svg {
          path {
            fill: #6b7280;
          }
        }
      }
  
      &-timer {
        margin-left: 10px;
      }
    }    
  }
}
// CropperPlaybackControl