.audio-duck {
  .timeline {
    font-size: medium;
  }

  .channel-icon {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    height: 4rem;
  }
  
  .channel-items {
    height: 4rem;
  }
  
  .channel-items-box {
    height: 4rem;
  }

  .channel-name {
    height: 4rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #3544a8;
    padding-right: 1rem;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

  }

 .timeline-inner {
    width: 30px;
    margin: 10px;
    .item-icon {      
      img {
        height: 20px;
        width: 30px;
      }
    }
  }
}

