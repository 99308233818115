.cerebriam-banner {
  background: $primaryBgGradient;
  padding: 50px 20px;
  height: 487px;
  .cerebriam-row {
    max-width: 1500px;
    margin: 0 auto;
    .cerebriam-col-4 {
      .cerebriam-content {
        color: $whiteColor;
        max-width: 100%;
        text-align: center;
        margin-top: 30px;
        h1 {
          font-size: 35px;
          font-weight: 700;
          line-height: 40px;
        }
        p {
          font-size: 26px;
          font-weight: 500;
          margin-top: 10px;
          margin-bottom: 15px;
        }
        p2 {
          font-size: 16px;
          font-weight: 500;
          margin-top: 10px;
          margin-bottom: 15px;
        }
        a {
          font-size: 20px;
          color: $blackColor;
          background-color: $whiteColor;
          border-radius: 50px;
          padding: 20px 20px;
          font-weight: 700;
          cursor: pointer;
          @include transition-ease;
    
          &:hover {
            opacity: 0.8;
          }
        }
        button {
          font-size: 20px;
          color: $blackColor;
          background-color: $whiteColor;
          border-radius: 50px;
          padding: 20px 20px;
          font-weight: 700;
          cursor: pointer;
          @include transition-ease;
    
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .cerebriam-col-6 {
      .cerebriam-content {
        position: relative;
        height: 100%;

        .video-content {
          width: 100%;
          border-radius: 19px;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 50px;
          img.cerebriam-banner-img, iframe, video {
            width: 100%;

            &:hover {
              cursor: pointer;
            }
  
            &:hover + .toggle-play {
              display: block;
            }
          }
          .toggle-play {
            width: 100px;
            position: absolute;
            left: calc(50% - 50px);
            top: calc(50% - 50px);
            display: none;
            @include transition-ease;

            &:hover {
              display: block;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

}

@media only screen and (min-width: $screen-md) {
  .cerebriam-banner {
    height: 452px;
    padding: 50px 40px;

    .cerebriam-row {
      .cerebriam-col-4 {
        .cerebriam-content {
          max-width: 500px;
          text-align: left;
          h1 {
            font-size: 60px;
            line-height: 50px;
          }
        }
      }
      .cerebriam-col-6 {
        .cerebriam-content {
          .video-content {
            top: 70px;
            img.cerebriam-banner-img, iframe, video {
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-lg) {
  .cerebriam-banner {
    padding: 10px 90px;
  }
}

@media only screen and (min-width: $screen-xl) {

}