$active-color: white;
.menu-area {
  .menu-items {
    background-color: #008180;
    border-radius: 0 30px 30px 0;
    width: 250px;
    box-shadow: 0 0 55px 10px rgba(0, 0, 0, 0.48);
    animation: show-modal-fadeIn ease-out 300ms;

    ul {
      li {
        cursor: pointer;
      }
    }
  }

  .quality-option {
    width: 250px;
    color: #4c6174;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px -2px rgba(17, 24, 39, 0.05), 0px 12px 16px -4px rgba(17, 24, 39, 0.10);

    .sub-top-item {
      height: 50px;
      background-color: #008180;
      color: white;
    }

    ul {
      li.active {
        background-color: #319795;
      }
    }
  }

  .item-icon {
    width: 40px;
  }

  .actions-menu-bar {
    width: 80px;
    height: 40px;
    left: 30px;
    bottom: 50px;

    @media (max-width: 1024px) {
      bottom: 30px;
      left: 20px;
    }

    @media (max-width: 768px) {
      bottom: 10px;
      left: 5px;
      width: 60px;
      height: 40px;
    }
  }
}

.side-area {
  .side-items {
    background-color: rgb(255, 255, 255);
    border-radius: 0 30px 30px 0;
    width: 300px;
    box-shadow: 0 0 55px 10px rgba(0, 0, 0, 0.48);
    //animation: show-modal-fadeIn ease-out 300ms;

    ul {
      li {
        cursor: pointer;
      }
    }
  }

  //.quality-option {
  //  width: 250px;
  //  color: #4c6174;
  //  border-radius: 0 30px 30px 0;
  //  background-color: #ffffff;
  //
  //  .sub-top-item {
  //    border-top-right-radius: 30px;
  //  }
  //
  //  ul {
  //    li.active {
  //      background-color: #319795;
  //    }
  //  }
  //}

  .item-icon {
    width: 40px;
  }

  //.actions-side-bar {
  //  width: 80px;
  //  height: 40px;
  //  left: 30px;
  //  bottom: 50px;
  //
  //  @media (max-width: 1024px) {
  //    bottom: 30px;
  //    left: 20px;
  //  }
  //
  //  @media (max-width: 768px) {
  //    bottom: 10px;
  //    left: 5px;
  //    width: 60px;
  //    height: 40px;
  //  }
  //}
}


.ratio-action-dropdown {
  top: 140px;
  right: 40px;
  flex-direction: column;
  z-index: 62;

  .landscape-box {
    width: 160px;
    height: 90px;
    z-index: 62;
  }

  .square-box {
    width: 90px;
    height: 90px;
    z-index: 62;
  }

  .portrait-box {
    width: 90px;
    height: 112.5px;
    z-index: 62;
  }

  .vertical-box {
    width: 90px;
    height: 160px;
  }

  @media (max-width: 1024px) {
    top: 120px;
    right: 30px;
  }

  @media (max-width: 768px) {
    top: 100px;
    right: 20px;
  }

  @media (max-width: 414px), (max-height: 414px) {
    top: 88px;
    right: 20px;

    .landscape-box {
      width: 80px;
      height: 45px;
      margin-bottom: 0.5rem;
    }

    .square-box {
      width: 45px;
      height: 45px;
      margin-bottom: 0.5rem;
    }

    .portrait-box {
      width: 45px;
      height: 56.25px;
      margin-bottom: 0.5rem;
    }

    .vertical-box {
      width: 45px;
      height: 80px;
    }
  }
}

.recorder-wrapper {
  .aspect-ratios-area {
    top: 20px;
    right: 150px;
    width: 100px;
    height: 100px;
    z-index: 63;

    .landscape-btn {
      width: 100px;
      height: 56.25px;
      bottom: 0;
      z-index: 1;
      background-color: #92b7be;

      @media (max-width: 1024px) {
        width: 80px;
        height: 45px;
      }

      @media (max-width: 768px) {
        width: 60px;
        height: 33.75px;
      }
    }

    .square-btn {
      width: 56.25px;
      height: 56.25px;
      z-index: 3;
      bottom: 0;
      background-color: #195a67;

      @media (max-width: 1024px) {
        width: 45px;
        height: 45px;
      }

      @media (max-width: 768px) {
        width: 33.75px;
        height: 33.75px;
      }
    }

    .vertical-btn {
      width: 56.25px;
      height: 100px;
      z-index: 2;
      background-color: #367a87;

      @media (max-width: 1024px) {
        width: 45px;
        height: 80px;
      }

      @media (max-width: 768px) {
        width: 33.75px;
        height: 60px;
      }
    }

    @media (max-width: 1024px) {
      width: 80px;
      height: 80px;
      right: 115px;
    }

    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
      right: 88px;
    }

    &:hover {
      .landscape-btn, .square-btn, .vertical-btn, img {
        transform: scale(0.95);
      }
    }
  }

  .mobile-actions {
    .aspect-ratios-area {
      right: 30px;

      @media (max-width: 768px) {
        top: 8px;
        right: 10px;
      }
    }
  }

  .desktop-actions {
    .record-btn-area {
      right: 40px;
      top: 40px;
      z-index: 63;

      @media (max-width: 1024px) {
        top: 30px;
        right: 30px;
      }

      @media (max-width: 768px) {
        top: 20px;
        right: 20px;
      }

      button {
        width: 115px;
        height: 48px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #008180;
          
        &:disabled {
          background: rgba(113, 128, 150, 0.8);
        }

        .recorder-icon {
          width: 16px;
          height: 16px;
          background-color: #fff;
          border-radius: 50%;
          margin-right: 8px;

          &.stop {
            border-radius: 2px;
          }
        }
      }
    }

    .record-count-timer {
      width: 200px;
      height: 200px;

      .timer-number {
        position: absolute;
        font-size: 100px;
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 0;
        animation: fadeout .5s 6s 1 linear;
        animation-fill-mode: forwards;
      }

      .l-half, .r-half {
        float: left;
        width: 50%;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        &:before {
          content: " ";
          display: block;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background-color: #6b46c1;
          animation-duration: 5s;
          animation-iteration-count: 1;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }

      .l-half {
        &:before {
          border-right: none;
          border-top-left-radius: 9999px;
          border-bottom-left-radius: 9999px;
          transform-origin: right center;
          animation-name: l-rotate;
        }
      }

      .r-half {
        &:before {
          border-left: none;
          border-top-right-radius: 9999px;
          border-bottom-right-radius: 9999px;
          transform-origin: left center;
          animation-name: r-rotate;
        }
      }
    }

    .countdown-switch-action {
      top: initial;
      left: initial;
      width: 45px;
      height: 45px;
      z-index: 60;

      .cancel-countdown {
        width: 45px;
        height: 45px;
        border: 3px solid #991f1f;
        top: 0;
        left: 0;

        &:before {
          content: ' ';
          width: 45px;
          position: absolute;
          height: 3px;
          background-color: #991f1f;
          transform: rotate(45deg);
          top: 17px;
          left: -2px;
        }
      }

      // @media (max-width: 1024px) {
      //   left: 75px;
      //   top: 35px;
      // }

      // @media (max-width: 768px) {
      //   top: 32px;
      //   left: 63px;
      // }

      img {
        width: 45px;
        height: 100%;
      }
    }
  }

  .shoot-action-box:hover {
    background: linear-gradient(0deg, rgba(0,0,0, 0.7), transparent);
    background-position: 0 0;
    transition: background-position 2s ease;
  }

  .shoot-action-box {   
    background-position: 0 -100%;
  }

  .shoot-action {
    bottom: 65px;
    z-index: 51;

    .shoot-item {
      width: 50px;
      height: 45px;

      @media (max-width: 768px) {
        width: 50px;
        height: 43px;
      }

      button {
        outline: 0;
        box-shadow: none;
        overflow: hidden;

        svg {
          outline: 0;
          box-shadow: none;
        }
      }
    }

    @media (max-width: 1024px) {
      bottom: 55px;
    }

    @media (max-width: 768px) {
      bottom: 115px;
    }
  }

  .mic-action {
    bottom: 40px;

    @media (max-width: 1024px) {
      bottom: 20px;
    }

    @media (max-width: 768px) {
      bottom: 10px;
    }

    .mic-wrapper {
      width: 96%;
      position: relative;
      top: -25px;
      left: 2%;

      canvas {
        width: 100%;
      }
    }

    .collaborator-action {
      width: 45px;
      height: 45px;
      padding: 5px;
      margin-left: 50px;
      margin-right: 40px;

      @media (max-width: 1024px) {
        margin-right: 20px;
      }

      @media (max-width: 768px) {
        margin-left: 30px;
        margin-right: 10px;
        width: 45px;
        height: 45px;
      }
    }
  }

  .mobile-replay-action {
    top: 40px;
    z-index: 63;

    @media (max-width: 768px) {
      top: 15px;
    }
  }

  .moveable-control-box {
    z-index: 51 !important;
  }

  .moveable-line {
    background-color: transparent !important;
  }

  .moveable-control {
    width: 50px !important;
    height: 50px !important;
    margin-top: -25px !important;
    margin-left: -25px !important;
    z-index: 20 !important;
    border: 0 !important;
    background-color: transparent !important;
    display: flex;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      background-color: #00d1ff;
      display: block;
      margin: auto;
    }

    &.moveable-se {
      margin-left: 0 !important;

      &:before {
        margin-left: -10px;
      }
    }
  }

  .replay-action {
    top: 40px;
  }

  .man-walk-action {
    width: 34px;
    height: 34px;
    z-index: 60;

    .cancel-man-walk {
      width: 42px;
      height: 42px;
      border: 3px solid #991f1f;
      bottom: -5px;
      left: -5px;

      &:before {
        content: ' ';
        width: 40px;
        position: absolute;
        height: 3px;
        background-color: #991f1f;
        transform: rotate(45deg);
        top: 15px;
        left: -2px;
      }
    }

    // @media (max-width: 1024px) {
    //   left: 30px;
    // }

    // @media (max-width: 768px) {
    //   top: 36px;
    //   left: 20px;
    // }

    // @media (max-width: 768px) {
    //   top: 15px;
    //   left: 15px;
    // }

    img {
      width: 26px;
      height: 100%;
    }
  }

  .snap-grid-save-check {
    z-index: 70;
    /* The checkbox container */
    .save-check-container {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input[type=checkbox] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        width: 28px;
        height: 28px;
        background-color: rgba(146, 45, 213, 0.3);

        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 10px;
          top: 3px;
          width: 9px;
          height: 18px;
          border: solid rgba(255, 255, 255, 0.5);
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      &:hover input[type=checkbox] ~ .checkmark {
        background-color: rgba(146, 45, 213, 0.5);
      }

      input[type=checkbox]:checked ~ .checkmark {
        background-color: #922dd5;
      }

      input[type=checkbox]:checked ~ .checkmark:after {
        display: block;
      }
    }

    .tooltip-text {
      width: 120px;
      background-color: #00000070;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      margin-left: -60px;
      border: 0;

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #00000070 transparent transparent transparent;
      }
    }
  }
}

.ratio-action {
  color: #922dd5;

  .snap-grid-top-border, .snap-grid-bottom-border {
    height: 3px;
    // background: linear-gradient(to right, #922dd5 35%, transparent 35%, transparent 37%, #922dd5 37%, #922dd5 39.67%, transparent 39.67%, transparent 41.67%, #922dd5 41.67%, #922dd5 44.34%, transparent 44.34%, transparent 46.34%, #922dd5 46.34%, #922dd5 49.01%, transparent 49.01%, transparent 51.01%, #922dd5 51.01%, #922dd5 53.68%, transparent 53.68%, transparent 55.68%, #922dd5 55.68%, #922dd5 58.35%, transparent 58.35%, transparent 60.35%, #922dd5 60.35%, #922dd5 63%, transparent 63%, transparent 65%, #922dd5 65%);
    background: #00d1ff;
  }

  .snap-grid-right-border, .snap-grid-left-border {
    width: 3px;
    // background: linear-gradient(to bottom, #922dd5 35%, transparent 35%, transparent 37%, #922dd5 37%, #922dd5 39.67%, transparent 39.67%, transparent 41.67%, #922dd5 41.67%, #922dd5 44.34%, transparent 44.34%, transparent 46.34%, #922dd5 46.34%, #922dd5 49.01%, transparent 49.01%, transparent 51.01%, #922dd5 51.01%, #922dd5 53.68%, transparent 53.68%, transparent 55.68%, #922dd5 55.68%, #922dd5 58.35%, transparent 58.35%, transparent 60.35%, #922dd5 60.35%, #922dd5 63%, transparent 63%, transparent 65%, #922dd5 65%);
    background: #00d1ff;
  }

  &.de-active {
    color: #007676;

    .snap-grid-top-border, .snap-grid-bottom-border {
      height: 3px;
      // background: linear-gradient(to right, #007676 35%, transparent 35%, transparent 37%, #007676 37%, #007676 39.67%, transparent 39.67%, transparent 41.67%, #007676 41.67%, #007676 44.34%, transparent 44.34%, transparent 46.34%, #007676 46.34%, #007676 49.01%, transparent 49.01%, transparent 51.01%, #007676 51.01%, #007676 53.68%, transparent 53.68%, transparent 55.68%, #007676 55.68%, #007676 58.35%, transparent 58.35%, transparent 60.35%, #007676 60.35%, #007676 63%, transparent 63%, transparent 65%, #007676 65%);
      background: #00d1ff;
    }

    .snap-grid-right-border, .snap-grid-left-border {
      width: 3px;
      // background: linear-gradient(to bottom, #007676 35%, transparent 35%, transparent 37%, #007676 37%, #007676 39.67%, transparent 39.67%, transparent 41.67%, #007676 41.67%, #007676 44.34%, transparent 44.34%, transparent 46.34%, #007676 46.34%, #007676 49.01%, transparent 49.01%, transparent 51.01%, #007676 51.01%, #007676 53.68%, transparent 53.68%, transparent 55.68%, #007676 55.68%, #007676 58.35%, transparent 58.35%, transparent 60.35%, #007676 60.35%, #007676 63%, transparent 63%, transparent 65%, #007676 65%);
      background: #00d1ff;
    }
  }

  &.active {
    color: $active-color;

    .snap-grid-top-border, .snap-grid-bottom-border {
      height: 3px;
      // background: linear-gradient(to right, $active-color 35%, transparent 35%, transparent 37%, $active-color 37%, $active-color 39.67%, transparent 39.67%, transparent 41.67%, $active-color 41.67%, $active-color 44.34%, transparent 44.34%, transparent 46.34%, $active-color 46.34%, $active-color 49.01%, transparent 49.01%, transparent 51.01%, $active-color 51.01%, $active-color 53.68%, transparent 53.68%, transparent 55.68%, $active-color 55.68%, $active-color 58.35%, transparent 58.35%, transparent 60.35%, $active-color 60.35%, $active-color 63%, transparent 63%, transparent 65%, $active-color 65%);
      background: #00d1ff;
    }

    .snap-grid-right-border, .snap-grid-left-border {
      width: 3px;
      // background: linear-gradient(to bottom, $active-color 35%, transparent 35%, transparent 37%, $active-color 37%, $active-color 39.67%, transparent 39.67%, transparent 41.67%, $active-color 41.67%, $active-color 44.34%, transparent 44.34%, transparent 46.34%, $active-color 46.34%, $active-color 49.01%, transparent 49.01%, transparent 51.01%, $active-color 51.01%, $active-color 53.68%, transparent 53.68%, transparent 55.68%, $active-color 55.68%, $active-color 58.35%, transparent 58.35%, transparent 60.35%, $active-color 60.35%, $active-color 63%, transparent 63%, transparent 65%, $active-color 65%);
      background: #00d1ff;
    }
  }
}

@keyframes l-rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes r-rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.ReactModal__Overlay {
  z-index: 200;
}
