.cerebriam-trial-banner {
    background-color: #ffc107;
    color: white;
    width: 100% !important;
    text-align: center;
    display: none;
  
    &.show {
      display: block;
    }
  
    p {
      a {
        text-decoration: underline;
        color: blue;
      }
    }
  }