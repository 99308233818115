.social-platforms {
  .ratio {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFDFDF;
    cursor: pointer;
    margin-left: -12px;
    @include transition-ease;

    span {
      color: #242D4E;
      font-size: 8px;
      font-weight: 700;
    }

    &.selected, &:hover {
      background: linear-gradient(180deg, #6D10A8 0%, #C2A1D8 100%);
      span {
        color: white;
      }
    }

    &.full-vertical {
      width: 25px;
      height: 33px;
    }

    &.semi-vertical {
      width: 30px;
      height: 33px;
    }

    &.square {
      width: 33px;
      height: 33px;
    }

    &.landscape {
      width: 45px;
      height: 33px;
    }
  }
}
.cropper-platform-ratios {
  &::before {
    position: absolute;
    content: '';
    left: 4px;
    top: 27px;
    height: 34px;
    width: 2px;
    background-color: #8C8C8C;
  }
}
.cropper-platform-ratios, .cropper-selected-ratios {
  display: flex;
  position: relative;
  padding-left: 20px;
  align-items: center;
  height: 88px;
  @include transition-ease;
  
  .ratio {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFDFDF;
    background: rgba(255,255,255,0.2);
    cursor: pointer;
    @include transition-ease;

    span {
      color: white;
      font-size: 8px;
      font-weight: 700;
    }

    &.selected, &:hover {
      background: linear-gradient(180deg, #6D10A8 0%, #C2A1D8 100%);
      span {
        color: white;
      }
    }

    &.full-vertical {
      width: 25px;
      height: 33px;
    }

    &.semi-vertical {
      width: 30px;
      height: 33px;
    }

    &.square {
      width: 33px;
      height: 33px;
    }

    &.landscape {
      width: 45px;
      height: 33px;
    }
  }
}

.cropper-selected-ratios {
  .ratio {
    cursor: auto;
    position: relative;
    .ratio-action-btns {
      display: none;
      position: absolute;
      top: 32px;
      left: 0;
      div {
        display: flex;
        align-items: center;
        justify-content: center;

        svg { width: 30px;}
      }

      .delete {
        margin-left: -5px;
      }
    }
    span {
      color: #fff;

      &.remove-icon {
        display: none;
        color: red;
        font-weight: 700;
        font-size: 20px;
        @include transition-ease;
      }
    }
    &:hover {
      background: transparent;
      cursor: pointer;
      span {
        // display: none;
        &.remove-icon {
          display: block;
  
        }
      }
      .ratio-action-btns {
        display: flex;
      }
    }
  }
}

// CropperAspectRatioSelection Component
.cropper-aspect-ratio-selection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  @include transition-ease;

  &.bottom-150 {
    bottom: 150px;
  }

  &.bottom-270 {
    bottom: 270px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    .cropper-tracking-off, .add-aspect-ratio {
      border: 2px solid #FFFFFF;
      border-radius: 8px;
      width: 261px;
      height: 48px;
      display: none;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      outline: none;
      background: rgba(0,0,0,0.5);
      @include transition-ease;

      svg {
        margin-right: 10px;
      }

      span {
        font-size: 14px;
        color: #fff;
      }

      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
    .cropper-tracking-off {
      width: 156px;
      height: 48px;
    }

    .cropper-tracking-on {
      background-color: #fff;
      border: 1px solid #FFFFFF;
      border-radius: 7px;
      width: 200px;
      height: 48px;
      display: none;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      outline: none;
      @include transition-ease;

      svg {
        margin-right: 10px;
      }

      span {
        font-size: 14px;
        color: #000;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .social-platforms-ratios {
      width: 472px;
      height: 88px;
      display: flex;
      background-color: rgba(255, 255, 255, 0.2);
      padding-right: 12px;
      padding-left: 12px;
      align-items: center;

      overflow-x: scroll;
      max-width: 100%;
      position: fixed;
      top: 0;
      left: calc(50% - 225px);    

      .social-platforms {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @include transition-ease;

        overflow-x: scroll;
        max-width: 100%;
        flex-shrink: 0;

        &.selected {
          width: 55px;
        }

        .social-platform {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          margin-right: 25px;
          flex-shrink: 0;
          opacity: 0.9;
          @include transition-ease;

          &:last-child {
            margin-right: 0;
          }
          &:hover {
            opacity: 1;
          }

          svg {
            width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .cropper-aspect-ratio-selection {
    // bottom: 150px;

    &__content {
      justify-content: space-between;
      padding-left: 5%;
      padding-right: 5%;

      &.centralize {
        justify-content: center;
      }
      .cropper-tracking-off, .cropper-tracking-on, .add-aspect-ratio {
        display: flex;
      }
      .cropper-tracking-off, .cropper-tracking-on {
        position: static;
      }
      .social-platforms-ratios {
        overflow: hidden;
        padding-right: 22px;
        padding-left: 22px;
        border-radius: 13px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        height: 88px;

        .social-platforms {
          overflow: hidden;
        }
      }
    }
  }
}
// CropperAspectRatioSelection Component

// CropperTopButtons Component
.toggle-cropper-tooltip-btn {
  width: 27px;
  cursor: pointer;
  z-index: 50;
  @include transition-ease;

  &:hover {
    opacity: 0.8;
  }
}
.cropper-top-buttons {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2%;

    svg {
      width: 27px;
      cursor: pointer;
      @include transition-ease;

      &:hover {
        opacity: 0.8;
      }
    }

    .reset-apply-ratios {
      width: 45px;
      height: 45px;
      border: 3px solid #991f1f;
      top: -6px;
      left: -5px;
      &:before {
        content: ' ';
        width: 45px;
        position: absolute;
        height: 3px;
        background-color: #991f1f;
        transform: rotate(45deg);
        top: 18px;
        left: -2px;
      }
    }

    .left-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -135px;

      .cropper-tracking-off {
        display: flex;
        height: 35px;
        width: 35px;
        border: 1px solid #fff;
        border-radius: 6px;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        @media only screen and (min-width: 992px) {
          display: none;
        }
      }

      .cropper-tracking-on {
        display: flex;
        height: 35px;
        width: 35px;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 6px;
        color: #000;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        @media only screen and (min-width: 992px) {
          display: none;
        }
      }
    }

    .cropper-selected-ratios {
      position: absolute;
      top: 70px;
      left: calc(50% - 130px);
      z-index: 50;

      @media (max-width: 1024px) {
        top: 120px;
      }

      @media (max-width: 800px) {
        top: 100px;
      }
    }


    .buttons {
      margin-top: -125px;
      button.complete-btn {
        width: 100px;
        background-color: #008180;
        outline: none;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
        height: 35px;
        border-radius: 6px;
        @include transition-ease;
  
        &:hover {
          opacity: 0.8;
        }
      }
      button.add-ratio-btn {
        height: 35px;
        width: 35px;
        border: 1px solid #fff;
        outline: none;
        border-radius: 6px;
        font-weight: 700;
        font-size: 14px;
        margin-left: 10px;
        @include transition-ease;
  
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

button.add-ratio-btn {
  height: 35px;
  width: 35px;
  border: 1px solid #fff;
  outline: none;
  border-radius: 6px;
  font-weight: 700;
  font-size: 14px;
  margin-left: 10px;
  @include transition-ease;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .toggle-cropper-tooltip-btn {
    width: 34px;
  }
  .cropper-top-buttons {
    &__content {
      svg {
        width: 34px;
      }
      .cropper-selected-ratios {
        position: static;
      }
      .buttons {
        button.complete-btn {
          height: 45px;
          width: 130px;
          font-size: 14px;
        }
        button.add-ratio-btn {
          display: none;
        }
      }
    }
  }
}
// CropperTopButtons Component

// CropperFacetrackPreview
.cropper-facetrack-preview {
  button.original {
    &:hover {
      background: #01C67F !important;
      opacity: 0.9;
    }
  }
  button.tracked {
    &:hover {
      opacity: 0.9;
    }
  }
}
// CropperFacetrackPreview

// Cropper Moveable

.recorder-wrapper {
  .moveable-n, .moveable-s, .moveable-e, .moveable-w {
    display: none !important;
  }
}

// Cropper Moveable

// CropperPlaybackControl
.cropper-playback-control {
  width: 80%;

  &__container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    width: 80%;

    &-slider {
      width: calc(100% - 100px);
    }

    &-play {
      width: 20px;
      margin-right: 30px;
      cursor: pointer;
    }

    &-timer {
      width: 80px;
      margin-left: 30px;
      font-size: 12px;
      opacity: 0.7;
    }
  }

  &.editor-review {
    width: 97%;
    margin: 0 auto;

    .cropper-playback-control__container {
      width: 100%;
      margin-top: 10px;

      &-play {
        margin-right: 15px;
        svg {
          path {
            fill: #6b7280;
          }
        }
      }
  
      &-timer {
        margin-left: 10px;
      }
    }    
  }
}
// CropperPlaybackControl

// CropperScenes
.cropper-scenes {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 135px;
  background-color: #1F1F1F;
  @include transition-ease;

  &-expander {
    position: absolute;
    top: -18px;
    left: calc(50% + 23px);
    width: 46px;
    height: 18px;
    background-color: #008180;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      rotate: 90deg;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    align-items: center;
    padding-top: 19px;

    &__left-control, &__right-control {
      background-color: grey;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #434343;
      border-radius: 12px;
      border: 1px solid #000;
      cursor: pointer;
      @include transition-ease;
      
      &:hover {
        opacity: 0.8;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      
        &:hover {
          opacity: 0.5;
        }
      }
    }

    &__scenes {
      display: flex;
      align-items: center;
      width: 90%;
      justify-content: start;
  
      .scene {
        width: 155px;
        height: 95px;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
        border-radius: 4px;

        &.active {
          border: 2px solid white;;
        }
  
        &:hover {
          opacity: 0.8;
          border: 2px solid white;;
        }
        &-img {
          position: relative;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            vertical-align: top;
          }
        }
  
        &-duration {
          position: absolute;
          bottom: 4px;
          left: 4px;
          width: 41px;
          height: 17px;
          background-color: #fff;
          color: #2D2D2D;
          border-radius: 4px;
          font-size: 11px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }

  &.full {
    height: 270px;

    .cropper-scenes-content {
      justify-content: center;
      height: 100%;
      overflow-y: scroll;
      .cropper-scenes-content__scenes {
        flex-wrap: wrap;
        height: 100%;
      }
    }
  }
}
// CropperScenes

// Cropper Toggle Apply Selected Ratios Modal
.apply-selected-ratios-modal {
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
  }

  &__selections {
    display: flex;
    margin-top: 20px;
    margin-bottom: 40px;
    .social-platforms {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100%;
      @include transition-ease;
      .social-platform {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 15px;
        flex-shrink: 0;
        opacity: 0.9;
        @include transition-ease;

        &:hover {
          opacity: 1;
        }
      }
    }
    .ratios {
      display: flex;
      padding-left: 5px;
      align-items: center;
      border-left: 2px solid #D9D9D9;
      .ratio {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #9C9C9C33;
        cursor: pointer;
        @include transition-ease;
    
        span {
          color: #595959;
          font-size: 8px;
          font-weight: 700;
        }
    
        &.full-vertical {
          width: 25px;
          height: 33px;
        }
    
        &.semi-vertical {
          width: 30px;
          height: 33px;
        }
    
        &.square {
          width: 33px;
          height: 33px;
        }
    
        &.landscape {
          width: 45px;
          height: 33px;
        }
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 115px;
      height: 48px;
      border-radius: 8px;
      font-weight: 600;
      @include transition-ease;

      &.cancel {
        background-color: rgba(0, 129, 128, 0.10);
        color: #008180;
      }
      &.apply {
        background-color: var(--008180, #008180);
        margin-left: 15px;
        color: white;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
// Cropper Toggle Apply Selected Ratios Modal
