.cropper-tooltip {
  position: fixed;
  background-color: white;
  color: black;
  display: inline;
  padding: 10px;
  width: 270px;
  border-radius: 8px;
  z-index: 100;

  &.hide {
    display: none;
  }

  &::after {
    position: absolute;
    border: 15px solid white;
    content: '';
  }
  &-close {
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
  }

  &-title {
    font-weight: 500;
    margin-bottom: 5px;
  }
  &-content {
    font-size: 14px;
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  button {
    background: #008080;
    color: #eee;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #008080;
    cursor: pointer;
  
    &:hover {
      background: #03b7b6;
      border-color: #03b7b6;
    }
  }

  &.step-1 {
    top: 80px;
    left: 38%;

    &::after {
      left: calc(50% - 25px);
      top: -28px;
      border-color: transparent transparent white transparent;

    }
    @media only screen and (max-width: 500px) {
      left: 10%;
    }
  }
  &.step-2 {
    bottom: 80px;
    right: 50px;

    &::after {
      left: calc(50% - 25px);
      bottom: -28px;
      border-color: white transparent transparent transparent;
    }

    @media only screen and (max-width: 991px) {
      right: 5px;
      top: 75px;
      bottom: inherit;
      
      &::after {
        right: 20px;
        top: -28px;
        left: inherit;
        bottom: inherit;
        border-color: transparent transparent white transparent;
      };
    }
  }
  &.step-3 {
    top: 70px;
    right: 28%;

    &::after {
      left: -28px;
      top: 20px;
      border-color: transparent white transparent transparent;
    }
    @media only screen and (max-width: 500px) {
      top: 60px;
      right: 5%;
    }
  }
  &.step-4 {
    bottom: 80px;
    left: 50px;

    &::after {
      left: calc(50% - 25px);
      bottom: -28px;
      border-color: white transparent transparent transparent;

    }
    @media only screen and (max-width: 991px) {
      left: 35px;
      top: 75px;
      bottom: inherit;
      
      &::after {
        left: 20px;
        top: -28px;
        bottom: auto;
        border-color: transparent transparent white transparent;
      };
    }
  }
}

.tooltip-mask {
  // z-index: 100;
  // background-color: red;
  // height: 500px;
  // width: 500px;
  // opacity: 0.8;
  // position: fixed;
  // top: 0;
  // left: 0;
}
.ratio-action {
  // background-color: blue !important;
  // z-index: 101;
}