.mc__field-container input {
	display: block;
	width: 100%;
  padding: 12px 10px;
  color: #666;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  line-height: normal;
  margin: 0;
  outline: none;
  margin: 0px 0 5px 0;
}

.mc__field-container label {
  color: #ddd;
}

.mc__field-container input:focus {
  border: 0 !important;
}

.mc__form .subscribe-button {
  border: 1px solid #020202;
    color: #020202;
    cursor: pointer;
    display: inline-block;
    font-family: 'Oswald', sans-serif;
    font-size: 1.0rem;
    line-height: normal;
    padding: 7px 20px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: white;
}

.mc__form .mc__title {
  font-size: 0.9rem;
  color: #ddd;
}

.mc__form .mc__alert {
  padding: 5px;
}

.mc__form .mc__alert--success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.mc__form .mc__alert--error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.mc__form .mc__alert--sending {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}