@import "mixins";

.uploadPage {
  .pageHeading {
    font-size: 36px;
    font-weight: 600;
    color: #1F2937
  }
}

// Home / Upload
.upload-border { 
  position: relative;
  padding: 30px;
  p {
    color: #1F2937;
    font-weight: 500;
    font-size: 18px;
  }
  .items {
    height: 100px !important;
    .icon-container {
      svg {
        width: 30px !important;
        height: 30px !important;
        @include transition-ease;
      }
    }
  }
  &:hover {
    box-shadow: 0px 24px 48px -12px rgba(17, 24, 39, 0.10);

    .icon-container {
      svg {
        width: 50px !important;
        height: 50px !important;
        path {
          fill: #008180;
        }
      }
    }
    p {
      color: #008180 !important;
    }
  }
  &.topr {
    &::after {
      background: linear-gradient(180deg, rgba(231, 231, 231, 0.00) 0%, #E3E3E3 36.29%, #D9D9D9 100%);
    }

    &.bottom {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to left, rgba(231, 231, 231, 0.00) 0%, #E3E3E3 56.29%, #D9D9D9 100%);
      }
    }
  }
  &.bottomr {
    &::after {
      background: linear-gradient(to top, rgba(231, 231, 231, 0.00) 0%, #E3E3E3 36.29%, #D9D9D9 100%);
    }
  }
  &.topr, &.bottomr {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
    }
  }
  &.borderb-full {
    border-bottom: 1px solid #D9D9D9;
  }
  &.borders-record {
    &::after {
      background: linear-gradient(180deg, rgba(231, 231, 231, 0.00) 0%, #E3E3E3 36.29%, #D9D9D9 100%);
      // background: linear-gradient(#E7E7E7 0%, #E3E3E3 100%, #D9D9D9 100%);
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, rgba(231, 231, 231, 0.00) 0%, #E3E3E3 56.29%, #D9D9D9 100%);
    }
  }
}

.upload-video-modal {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  .modal-title {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #000;
  }
  .dropzone-section {
    border-radius: 12px;
    border: 1px dashed  #9CA3AF;
    background: #F9FAFB;
    height: 200px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .upload-icon {
      width: 60px;
      height: 60px;
      border-radius: 15px;
      background-color: #008180;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      svg {
        width: 30px;
      }
    }
  }

  .or-text {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #6B7280;
  }

  .video-url-form {
    position: relative;
    input {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      padding: 10px;
      height: 40px;
      outline: none;
      padding-right: 80px;
      &:focus {
        border: 2px solid #D1D5DB; 
      }
      &::placeholder {
        color: #6B7280;
        font-size: 12px;
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      background: #008180;
      color: white;
      border-radius: 8px;
      font-size: 12px;
      height: 40px;
      width: 70px;
      outline: none;
      @include transition-ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .form-divider {
    width: 100%;
    height: 1px;
    background-color: #F3F4F6;
    margin-top: 22px;
  }

  .apply-segmentation {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    label.apply {
      font-size: 12px;
      margin-left: 5px;
      span {
        a {
          color: #008180;
          font-weight: 600;

          &:visited {
            color: #008180;
          }
        }
      }
    }
  }
}

.select-radio {
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.15);
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: none;
  }

  &.active {
    background-color: #008180;

    svg {
      display: block;
    }
  }
}

.scenes-selection-modal {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  .modal-title {
    font-weight: 600;
    font-size: 36px;
    text-align: center;
    color: #000;
  }
  .select-all-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 16px;
      font-weight: 500;
      margin-left: 12px;
    }
  }
  .scenes {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;

    .scene {
      width: 170px;
      height: 109px;
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;
      @include transition-ease;

      &:hover {
        opacity: 0.8;
      }
      &-img {
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
          content: '\A';
          position: absolute;
          width: 100%; height:100%;
          top:0; left:0;
          background:rgba(0,0,0,0.3);
          border-radius: 5px;
          opacity: 1;
          transition: all 1s;
          -webkit-transition: all 1s;
        }
        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
          border-radius: 5px;
        }
      }

      &-duration {
        position: absolute;
        bottom: 4px;
        left: 4px;
        width: 41px;
        height: 17px;
        padding: 2px, 6px, 2px, 6px;
        background-color: #fff;
        color: #2D2D2D;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 600;
        text-align: center;
      }

      &-radio {
        position: absolute;
        bottom: 4px;
        right: 4px;
        margin-right: 0px;
        border: 2px solid white;

        &.active {
          border: none;
          border: 2px solid #008180;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
      font-weight: 600;
      width: 149px;
      border-radius: 8px;
      height: 56px;
      font-size: 16px;
      outline: none;
      @include transition-ease;

      &:hover {
        opacity: 0.8;
      }
      &.back {
        background: rgba(0, 129, 128, 0.10);
        color: #008180;
      }
      &.next {
        background: #008180;
        color: #fff;
      }
    }
  }
}