.cerebriam-features {
  padding: 20px;
  padding-top: 180px;
  background-color: #fbfbff;

  .cerebriam-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    h2 {
      font-size: 22px;
      color: $primaryColor;
      font-weight: 700;
    }
    h3 {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .cerebriam-ratios {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .cerebriam-ratio {
        margin: 5px;
        width: 150px;
        height: 160px;
        border-radius: 16px;
        background-color: #fff;
        border-radius: 16px;

        box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
        overflow: hidden;

        .cerebriam-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .icon-container {
            margin-top: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #C4C4C4;
            border-radius: 9px;
          }

          p.ratio-code {
            font-weight: 700;
            font-size: 16px;
            margin-top: 10px;
            margin-bottom: -5px;
          }

          p.ratio-title {
            font-weight: 500;
            font-size: 12px;
          }
        }
        &.ratio-9-16 {
          .cerebriam-content {
            .icon-container {
              width: 54px;
              height: 69px;

              img {
                width: 23px;
                height: 27px;
              }
            }
          }
        }
        &.ratio-4-5 {
          .cerebriam-content {
            .icon-container {
              width: 83px;
              height: 69px;

              img {
                width: 16px;
                height: 31px;
              }
            }
          }
        }
        &.ratio-1-1 {
          .cerebriam-content {
            .icon-container {
              width: 69px;
              height: 69px;

              img {
                width: 37px;
                height: 30px;
              }
            }
          }
        }
        &.ratio-16-9 {
          .cerebriam-content {
            .icon-container {
              width: 123px;
              height: 69px;

              img {
                width: 42px;
                height: 28px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-md) {
  .cerebriam-features {

    .cerebriam-container {
      h2 {
      }
      h3 {
        font-size: 40px;
      }

      .cerebriam-ratios {
        .cerebriam-ratio {
          width: 211px;
          height: 226px;
          border-radius: 22px;

          .cerebriam-content {
            p.ratio-code {
              font-size: 28px;
              margin-bottom: -5px;
            }
  
            p.ratio-title {
              font-size: 16px;
            }
          }
          &.ratio-9-16 {
            .cerebriam-content {
              .icon-container {
                width: 71px;
                height: 91px;
  
                img {
                  width: 31px;
                  height: 36px;
                }
              }
            }
          }
          &.ratio-4-5 {
            .cerebriam-content {
              .icon-container {
                width: 109px;
                height: 91px;
  
                img {
                  width: 21px;
                  height: 40px;
                }
              }
            }
          }
          &.ratio-1-1 {
            .cerebriam-content {
              .icon-container {
                width: 91px;
                height: 91px;
  
                img {
                  width: 49px;
                  height: 40px;
                }
              }
            }
          }
          &.ratio-16-9 {
            .cerebriam-content {
              .icon-container {
                width: 162px;
                height: 91px;
  
                img {
                  width: 54px;
                  height: 37px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: $screen-lg) {
  .cerebriam-features {

  }
}
@media only screen and (min-width: $screen-xl) {
  .cerebriam-features {

  }
}