.cerebriam-services {
  background-color: #fbfbff;
  .cerebriam-container {
    background-color: #fbfbff;
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: scroll;
    .cerebriam-content {
      flex-shrink: 0;
      width: 228px;
      height: 230px;
      border-radius: 11px;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
      overflow: hidden;
      padding: 30px 15px;
      @include transition-ease;

      &:nth-child(2) {
        margin-left: 30px;
        margin-right: 30px;
      }

      svg {
        width: 31px;
      }

      h3 {
        font-weight: 700;
        font-size: 16px;
        color: #1E266D;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;
        color: #455880;
      }

      &:hover {
        background: $primaryBgGradient;
        cursor: pointer;
        color: #fff;

        h3, p {
          color: #fff;
        }

        svg path {
          fill: #fff;
        }
      }
    }
  }

  & > .cerebriam-row {
    max-width: 1600px;
    margin: 0 auto;
  }
  
  .cerebriam-row {
    background-color: #f5f5fb;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;

    &.walkthrough {
      padding-bottom: 0px;
      padding-top: 15px;
    }
    
    .cerebriam-col-6 {
      width: 100%;
      .cerebriam-content {
        padding: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        img.mobile {
          display: block;
        }
        img.desktop {
          display: none;
        }

        h2 {
          font-size: 32px;
          line-height: 40px;
          font-weight: 700;
          color: #242D4E;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          margin-top: 10px;
          margin-bottom: 20px;
          line-height: 24px;
          color: #455880;
        }
        a {
          font-size: 14px;
          padding: 10px 15px;
          border-radius: 30px;
          background-color: $secondaryColor;
          color: #fff;
          font-weight: 700;
          @include transition-ease;

          &:hover {
            opacity: 0.8;
          }
        }
        button {
          font-size: 14px;
          padding: 10px 15px;
          border-radius: 30px;
          background-color: $secondaryColor;
          color: #fff;
          font-weight: 700;
          @include transition-ease;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      @media only screen and (max-width: $screen-md) {
        &.content {
          order: 1;
        }
        &.image, &.images {
          order: 2;
        }
  
      }
    }

    &.cerebriam-tracking {
      .images {
        .cerebriam-card {
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          border-radius: 30px;
          padding: 10px;

          .cerebriam-image {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 5px;
            margin-right: 5px;

            h3 {
              font-weight: 700;
              font-size: 16px;
              margin-bottom: 5px;
            }
          }
        }
      }
      .content {
        .cerebriam-content {
        }
      }
    }

    &.partnership {
      height: 100%;
      padding: 0;
      background-color: #f9fbff;
      padding-top: 50px;
      .cerebriam-col-6 {
        padding: 0;
        .cerebriam-content {
          padding: 0;
          margin: 0;
        }

        &.texts {
          .cerebriam-content {
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            max-width: 500px;
            margin: 0 auto;

            h2 {
              font-size: 22px;
              color: $primaryColor;
              font-weight: 700;
            }
            h3 {
              font-size: 35px;
              font-weight: 700;
              margin-bottom: 30px;
            }
            .partners-logos {
              background-color: #f9fbff;
              padding: 0;
              margin-bottom: 20px;
            }
          }
        }

        &.bg-img {
          background-image: url(./../../assets/images/landing-page/partnership_bg_2.png);
          background-repeat: no-repeat;
          background-size: cover;

          background-position: center center;
          height: 400px;
        }
      }
    }
  }

  .dark-bg {
    height: 450px;
    background-image: url(./../../assets/images/landing-page/dark_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    color: #fff;

    .cerebriam-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 20px;
      text-align: center;

      h2 {
        font-weight: 700;
        font-size: 30px;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 15px;
      }
      a {
        font-size: 14px;
        padding: 15px 30px;
        border-radius: 30px;
        background-color: transparent;
        border: 1px solid white;
        color: #fff;
        font-weight: 700;
        @include transition-ease;
  
        &:hover {
          opacity: 0.8;
        }
      }
      button {
        font-size: 14px;
        padding: 15px 30px;
        border-radius: 30px;
        background-color: transparent;
        border: 1px solid white;
        color: #fff;
        font-weight: 700;
        @include transition-ease;
  
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .testimonial {
    height: 500px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    .testimonial-bg-img {
      position: absolute;
      display: none;

      &.left {
        bottom: 150px;
        left: 0;
      }
      
      &.right {
        top: 70px;
        right: 0;
      }

    }
    .cerebriam-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      max-width: 600px;
      margin: 0 auto;
      text-align: center;
      h2 {
        font-size: 22px;
        color: $primaryColor;
        font-weight: 700;
      }
      h3 {
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 20px;
        color: #242D4E;
      }
      p {
        color: #79869F;
        @include transition-ease;
      }
      .photos {
        margin-top: 20px;
        display: flex;
        overflow-x: scroll;
        max-width: 100%;
        .photo {
          flex-shrink: 0;
          height: 90px;
          width: 90px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px;
          cursor: pointer;
          @include transition-ease;
          
          &:hover {
            opacity: 0.8;
          }
           
          img {
            width: 83px;
            height: 83px;
            border-radius: 50%;
            @include transition-ease;
          }

          &.active {
            height: 125px;
            width: 125px;
            border: 2px solid #79869F;
            img {
              width: 107px;
              height: 107px;
            }
          }
        }

      }
      p.author-name {
        font-size: 18px;
        font-weight: 500;
        @include transition-ease;
      }
    }
  }
}

@media only screen and (min-width: $screen-md) {
  .cerebriam-services {
    .cerebriam-container {
      
      .cerebriam-content {
        width: 365px;
        height: 368px;
        border-radius: 18px;
        padding: 60px 30px;

        svg {
          width: 50px;
        }

        h3 {
          font-size: 24px;
          margin-top: 18px;
          margin-bottom: 25px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  
    .cerebriam-row {
      padding-top: 70px;
      padding-bottom: 70px;
      padding-left: 30px;
      padding-right: 30px;

      &.walkthrough {
        padding-bottom: 70px;
        padding-top: 30px;

        .content {
          padding-right: 50px;
          &.second{
            padding-left: 50px;
            padding-right: inherit;
          }
        }
      }
      
      .cerebriam-col-6 {
        width: 50%;
        .cerebriam-content {
          text-align: left;
          align-items: flex-start;

          img.mobile {
            display: none;
          }
          img.desktop {
            display: block;
          }
  
          h2 {
            font-size: 40px;
            line-height: 50px;
          }
          p {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }

      &.cerebriam-tracking {
        .images {
          .cerebriam-card {
            padding: 30px;
  
            .cerebriam-image {
  
              h3 {
                font-size: 22px;
              }
            }
          }
        }
        .content {
          padding-right: 50px;
          .cerebriam-content {
            margin-top: 40px;
          }
        }
      }

      &.partnership {
        height: 700px;
        padding-top: 0;
        .cerebriam-col-6 {
          &.texts {
            .cerebriam-content {
              padding-left: 50px;
              padding-right: 50px;
              h3 {
                font-size: 40px;
              }
            }
          }
  
          &.bg-img {
            background-position: left top;
            height: inherit;
          }
        }
      }
    }
    .testimonial {
      .cerebriam-content {
        h3 {
          font-size: 40px;
        }
        .photos {
          max-width: 600px;
          overflow-x: visible;
        }
        p.author-name {
          font-size: 25px;
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-lg) {
  .cerebriam-services {
    .cerebriam-row {

      &.walkthrough {

        .content {
          padding-right: 100px;
          &.second{
            padding-left: 100px;
            padding-right: inherit;
          }
        }
      }
      &.cerebriam-tracking {
        .content {
          padding-right: 100px;
        }
      }
    }
    .testimonial {
      .testimonial-bg-img {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: $screen-xl) {
  .cerebriam-services {
   
  }
}