@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cerebriam-subscribe {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  .spinner {
    animation: spin infinite 5s linear;
  }

  button:disabled,
  button[disabled]{
    border: 1px solid #e6e6e6 !important;
    background-color: #e6e6e6 !important;
    color: lightgrey !important;
    cursor: not-allowed !important;
  }
}

.cerebriam-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

@media (max-width: 640px) {
  .cerebriam-container {
    padding: 10px;
  }
}

// CEREBRIAM HEADER
.cerebriam-header {
  margin-bottom: 30px;
  margin-top: -40px;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cerebriam-back__btn {
      width: 120px;
      border: 2px solid lightgrey;
      border-radius: 5px;
      padding: 15px 10px;
      text-align: center;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media (max-width: 640px) {
  .cerebriam-header {
    &__container {
      img {
        width: 150px;
      }
      .cerebriam-back__btn {
        width: 80px;
        padding: 5px 5px;
        font-size: 12px;
      }
    }
  }
}
// CEREBRIAM HEADER


// CEREBRIAM STEPS

.cerebriam-steps {
  display: inline-block;
  display: flex;
  justify-content: center;

  .cerebriam-step {
    position: relative;
    cursor: pointer;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__value {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #e0e7ef;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }

    &::after {
      content: '';
      background: #ccc;
      height: 1px;
      width: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: -1;
    }

    &.active::after {
      background: #008080;
    }

    &.current {
      .cerebriam-step__value {
        border: 2px solid #008080;
      }
    }
  }
}

@media (max-width: 640px) {
  .cerebriam-steps {
    .cerebriam-step {
      &__title {
        font-size: 10px;
      }
    }
  }
}

// CEREBRIAM STEPS


// CEREBRIAM ORDER SUMMARY
.cerebriam-order {
  h2, p {
    font-weight: 600;
  }

  h2 {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
  }
}
// CEREBRIAM ORDER SUMMARY


// CEREBRIAM DETAILS i.e. FORM and BILLING CYCLE
.cerebriam-details {
  font-size: 13px;

  .cerebriam-row {
    display: flex;
    justify-content: space-between;
  
    .cerebriam-column {
      width: 40%;
    }
  
  }
  
  @media (max-width: 768px) {
    .cerebriam-row {
      flex-wrap: wrap;
      .cerebriam-column {
        width: 100%;
      }
    }
  }
  .cerebriam-form {
    margin-bottom: 20px;
    width: 100%;

    &.signup {
      margin-bottom: 0;
    }

    .cerebriam-title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 18px;

      &.signup {
        text-align: left;
      }
    }

    .terms {
      font-size: 16px;
      color: grey;
      margin-top: 15px;
      margin-bottom: 15px;

      a {
        color: #3a3aff;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    .stripe__card-element {
      border: 1px solid #c7c7c7;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 5px;
      transition: box-shadow 300ms ease-out;

      &:hover {
        box-shadow: 0px 0px 2px #008080;
      }
    }

    input, select {
      height: 40px;
      border: 1px solid #c7c7c7;
      width: 100%;
      border-radius: 5px;
      padding: 10px;
      transition: box-shadow 300ms ease-out;

      &:focus, &:active, &:hover {
        box-shadow: 0px 0px 2px #008080;
        outline: none;
      }
    }

    label.error {
      color: #dc3545;
      font-size: 10px;
    }

    .cerebriam-row-1, .cerebriam-row-2, .cerebriam-row-3 {
      margin-bottom: 7px;
    }

    .cerebriam-row-2 {
      display: flex;
      justify-content: space-between;


      .cerebriam-input {
        width: 49%;
      }
    }

    .cerebriam-row-3 {
      display: flex;
      justify-content: space-between;


      .cerebriam-input {
        width: 24%;
      }
      .cerebriam-input:first-child {
        width: 48%;
      }
    }

    .pay-button {
      background: #008080;
      color: #eee;
      font-size: 1rem;
      font-weight: 700;
      padding: 10px 20px;
      border-radius: 5px;
      border: 1px solid #008080;
      width: 100%;
  
      &:hover {
        background: #03b7b6;
        border-color: #03b7b6;
      }
  
      &:disabled,
      &[disabled]{
        border: 1px solid #e6e6e6;
        background-color: #e6e6e6;
        color: lightgrey;
      }
    }
  
    button {
      transition: all 0.5s ease;
    }
  }

  .cerebriam-billing-cycle {
    margin-bottom: 20px;
    position: relative;
    .cerebriam-title {
      font-weight: 500;
      text-align: center;
      font-size: 18px;
    }

    label {
      opacity: 0.6;
      font-size: 15px;

      &.active {
        opacity: 1;
      }
    }

    .discount-info {
      margin-bottom: 20px;
      font-size: 16px;
    }
    input[type="radio"] {
      accent-color: black;
    }
    .cerebriam-plan-option {
      width: 100%;
      display: flex;
      margin-top: 20px;
      margin-bottom: 15px;

      label {
        &:last-child {
          margin-left: 40px;
        }


        .radio-title {
          color: #008080;
        }
      }
    }
    .bottom-button {
      margin-top: 50px;
      button {
        border: 1px solid #008080;
        color: #008080;
        font-size: 1rem;
        font-weight: 500;
        padding: 10px 20px;
        border-radius: 5px;
        width: 100%;
        position: absolute;
        bottom: 0;

        &:hover {
          opacity: .9;
        }
      }
    }
    @media (min-width: 769px) {
      .bottom-button {
        margin-top: 0;
      }
    }
  }

}
// CEREBRIAM DETAILS i.e. FORM and BILLING CYCLE


// PRODUCT DETAILS
.products-table {
  width: 100%;
}
.products-table tr td, .products-table tr th {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;

  h3 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 20px;
  }
  p {
    color: rgb(163, 163, 163);
    font-size: 12px;
  }
}
.products-table tr.first-row td, .products-table tr.first-row th {
  border-top: 0;
}
.products-table tr td.first-column, .products-table tr th.first-column {
  border-left: 0;
}
.products-table tr td.last-column, .products-table tr th.last-column {
  border-right: 0;
}
.products-table tr.last-row td, .products-table tr.last-row th {
  border-bottom: 0;
}
.products-table tbody tr td {
  width: 25%;
}
.products-table tbody tr td:first-child {
  text-align: left;
}
.products-table .pricing-subscribe-btn {
  background: #02807f;
  color: #fff;
  width: 100%;
  font-size: 12px;
  border-radius: 5px;
  height: 40px;
  margin-top: 10px;
  font-weight: bold;
}
.tick-icon {
  vertical-align: middle;
  display: inline;
  width: 20px;
  height: auto;
  border: none;
}
// PRODUCT DETAILS


// REVIEW AND CONFIRM COMPONENT
.cerebriam-review-confirm {
  h2, p {
    font-weight: 600;
  }
  p.description {
    margin-top: 15px;
    margin-bottom: 35px;
  }

  h2 {
    font-size: 25px;
    margin-top: 30px;
  }

  p {
    font-size: 18px;
  }

  .cerebriam-price-summary {
    max-width: 500px;
    .cerebriam-item {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 600;
      }
    }

    .cerebriam-confirm {
      margin-bottom: 20px;
      margin-top: 20px;
      button {
        background-color: #008080;
        color: white;
        width: 100%;
        border-radius: 5px;
        height: 40px;
        font-size: 16px;
        transition: all 0.5s ease;
        &:hover {
          background: #03b7b6;
          border-color: #03b7b6;
        }
      }
    }
    .further-desc {
      font-size: 12px;
      font-weight: inherit;

      a {
        color: rgb(58, 58, 255);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
// REVIEW AND CONFIRM COMPONENT


// NAVIGATION BUTTONS COMPONENT

.cerebriam-navigation-buttons {
  display: flex;
  justify-content: space-around;

  &.cerebriam-container {
    padding: 0;
  }

  .cerebriam-btn.next {
    background: #008080;
    color: #eee;
    font-size: 1rem;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #008080;

    &:hover {
      background: #03b7b6;
      border-color: #03b7b6;
    }

    &:disabled,
    &[disabled]{
      border: 1px solid #e6e6e6;
      background-color: #e6e6e6;
      color: lightgrey;
    }
  }

  button {
    transition: all 0.5s ease;
  }
  .cerebriam-btn.previous {
    background: inherit;;
    color: #008080;
    font-size: 1rem;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #008080;

    &:hover {
      opacity: 0.8;
    }

    &:disabled,
    &[disabled]{
      border: 1px solid #e6e6e6;
      background-color: #e6e6e6;
      color: lightgrey;
    }
  }
}

// NAVIGATION BUTTONS COMPONENT


// CLIENTS SECTION COMPONENT
.cerebriam-clients {
  padding-bottom: 0;
  margin-top: 50px;

  .title {
    font-weight: 600;
    margin-top: 60px;
    margin-bottom: 45px;
  }
  &__logos {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: 170px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 640px) {
  .cerebriam-clients {
    &__logos  {
      flex-direction: column;
      align-items: center;
    }
  }
}
// CLIENTS SECTION COMPONENT

.css-p27qcy[aria-checked=true], .css-p27qcy[data-checked] {
  --switch-bg: #008080 !important;
}

.cerebriam-subscribe {
  .scenes {
    display: flex;
    justify-content: space-between; 

    .item {
      width: 24%;

      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 640px) {
    .scenes {
      flex-wrap: wrap;
      div {
        margin-bottom: 15px;
        width: 100% !important;
      }
    }
  }
    
}

.cerebriam-subscribe {
  .brands {
    display: flex;
    justify-content: space-between; 
    div {
      width: 19%;

      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 640px) {
  .brands {
    flex-wrap: wrap;
      div {
        margin-bottom: 15px;
        width: 100% !important;
      }
    }
  }
}