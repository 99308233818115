@import "mixins";
.modalApp{
    width: 769px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    padding: 2% 7% !important;

    @include breakpoint(mobileL) {
        min-width: calc(100vw - 100px);
      }

    h2 {
        font-size: 24px;
        font-weight: bold;
        font-family: cairo;
        letter-spacing: 0.5px;
        line-height: 40px;
        text-align: center;
        color: #000;
    }
    .sub-title {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    .content {
        width: 100%;
        padding: 30px 0px;
    }

    p.infoLines{
        line-height: 30px;
        display: flex;
        border-bottom: 1px solid#E5E7EB;
        padding: 12px 0;
        font-size: 16px;

        &.no-border-bottom {
            border-bottom: 0;
        }
        span {
            &:first-child{
                width: 50%;
            } 
           
        }

        &.copied {
            position: absolute;
            bottom: -32px;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }

    input, textarea {
        border-color: #D1D5DB;
        outline: none;

        &:focus {
            border-color: #2563EB;
        }
        &::placeholder {
            color: #6B7280;
        }
    }

    form{
        display: flex;
        flex-direction: column;
        textarea {
            border: 1px solid #e2e8f0;
            padding: 10px;
            margin: 20px 0px;
            min-height: 100px;
            outline: none;
        }
        
    }
    button{
        color: #fff;
        width: 100%;
        border-radius: 8px;
        height: 40px;
        font-weight: 14px;
        font-weight: 600;
        @include transition-ease;
    }
    .react-responsive-modal-closeButton {
        width: 30px;
       svg {
        color: red;

        &::after, &::before {
            border-color: red;
        }
        path {
            &::after, &::before {
                border-color: red;
            }
        }
       }
    }
    .shareIcons {
        display: flex;
        justify-content: center;

        a{
        margin: 0 10px;
        cursor: pointer;
        }
    }

    .shareApp {
        display: flex;
        flex-direction: column;
        position: relative;
       button {
            margin-top: 5px;
        }
    }

}

.cerebriam-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 14px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #fff;
        border: 1px solid #D1D5DB;
        border-radius: 4px;
    }
    
    
    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
      
      /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: #008180;
        border: 1px solid #008180;
    }
      
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
      
    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }
      
    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(35deg);
        -ms-transform: rotate(35deg);
        transform: rotate(35deg);
    }
}

.cerebriam-spinner {
    animation: cerebriam-spin 2s linear infinite;
}

@keyframes cerebriam-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}